import React from 'react';
import intl from '@biotmed/i18n';
import { CustomAttribute } from '@biotmed/settings-sdk';
import { Information } from 'src/components/forceConfirmation';
import { ADBLearnMoreLink } from 'src/utils/analyticsDBUtils';
import messages from '../dictionary';

const editForceConfirmationProps = (errorDetails: any, attributesMapper?: { [key: string]: CustomAttribute }) => {
  return {
    submitLabel: intl.current.formatMessage(messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_SUBMIT),
    title: intl.current.formatMessage(messages.EDIT_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE),
    information: () => (
      <Information
        applyConsequencesMessage={intl.current.formatMessage(
          messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_CONSEQUENCES_ACTION,
          {
            link: ADBLearnMoreLink,
            br: <br />,
          },
        )}
        errorDetails={errorDetails}
        attributesMapper={attributesMapper}
      />
    ),
  };
};

export default editForceConfirmationProps;
