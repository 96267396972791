import React, { ReactNode, useState } from 'react';
import { Separator, Icon, otherIcons, PopperWithAnchor, Button } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import {
  Content,
  DataContainer,
  PopperComponent,
  StatusWrapper,
  SubTitle,
  SyncControlWrapper,
  StatusTitleWrapper,
} from './ADBPage.styled';
import TooltipContent from './TooltipContent';

interface ContentViewProps {
  syncControlText: string;
  ValidationModal?: ReactNode;
  SyncButton: ReactNode;
  AdbStatus: ReactNode;
}

const ContentView: React.FC<ContentViewProps> = props => {
  const { syncControlText, ValidationModal, SyncButton, AdbStatus } = props;
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const theme = useTheme();

  const handleInfoClick = () => {
    setOpen(previousOpen => !previousOpen);
  };

  return (
    <Content>
      <StatusWrapper>
        <StatusTitleWrapper>
          <SubTitle>{intl.formatMessage({ id: 'analytics-db.status-header', defaultMessage: 'Status' })}</SubTitle>
          <PopperWithAnchor
            open={open}
            modifiers={[
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [30, 0],
                },
              },
            ]}
            PopperComponent={
              <PopperComponent>
                <TooltipContent />
              </PopperComponent>
            }
            handleClickAway={() => setOpen(false)}
          >
            <Button onClick={handleInfoClick}>
              <Icon IconComponent={otherIcons.info} color={theme.palette.grayScale.dark} width="16px" />
            </Button>
          </PopperWithAnchor>
        </StatusTitleWrapper>

        {AdbStatus}
      </StatusWrapper>
      <Separator height="49px" />
      <SyncControlWrapper>
        <SubTitle>
          {intl.formatMessage({ id: 'analytics-db.sync-control-header', defaultMessage: 'Sync Control' })}
        </SubTitle>
        <DataContainer>{syncControlText}</DataContainer>
        {SyncButton}
      </SyncControlWrapper>
      {ValidationModal}
    </Content>
  );
};

export default ContentView;
