import React from 'react';
import { CopyToClipboard } from '@biotmed/base-components';
import { ConnectionDetailFieldSubTitle } from './ADBPage.styled';

interface ConnectionDetailFieldProps {
  fieldValue: string;
  fieldName: string;
}

const ConnectionDetailField: React.FC<ConnectionDetailFieldProps> = props => {
  const { fieldValue, fieldName } = props;

  return (
    <>
      <ConnectionDetailFieldSubTitle>{fieldName}</ConnectionDetailFieldSubTitle>
      {fieldValue}
      <CopyToClipboard valueToCopy={fieldValue} />
    </>
  );
};

export default ConnectionDetailField;
