import { Icon, otherIcons } from '@biotmed/base-components';
import { useFeature } from '@biotmed/react-flags-lib';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Cookie, SelectedExternalPageTabData } from '@biotmed/settings-sdk';
import {
  AdvancedEditIconButton,
  Container,
  InputWrapper,
  StyledExpandableInput,
  StyledInput,
  UrlInputWrapper,
} from './DraggableTab.styled';
import { GenericTabProps } from './interfaces';
import AdvancedEditModal from './AdvancedEditModal';
import DynamicUrlToolTip from './DynamicUrlToolTip';
import WithAlignment from './WithAlignment';
import { CenteredIcon, StyledInfoTooltip } from './AdvancedEditModal.styled';

export interface ExternalDraggableTabProps extends GenericTabProps {
  data: SelectedExternalPageTabData;
}

const ExternalDraggableTab: React.FC<ExternalDraggableTabProps> = props => {
  const { data, onChange, errors, name } = props;
  const { isFeatureActive: isNoCodeParamsFeatureActive } = useFeature({ flag: 'NO_CODE_PARAMS' });
  const { isFeatureActive: isAdvancedEditFeatureActive } = useFeature({ flag: 'EXTERNAL_PAGE_ADVANCED_EDIT' });
  const [isAdvancedEditModalOpen, setIsAdvancedEditModalOpen] = React.useState(false);

  const isUsingCookies = (data?.cookies?.length ?? 0) > 0;
  const intl = useIntl();
  const theme = useTheme();

  const handleChange = (value: any) => {
    onChange(value);
  };

  const updateTitle: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = event => {
    const newValue = {
      ...data,
      title: event.target.value,
    };
    handleChange(newValue);
  };

  const cleanUrl = (url: string) => {
    return url.replaceAll(/[\s]+/g, '');
  };

  const updateUrl: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = event => {
    const url = cleanUrl(event.target.value);
    const newValue = {
      ...data,
      url,
    };

    handleChange(newValue);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
    }
  };

  const updateFromAdvancedEditModal = (values: { url: string; cookies: Cookie[] }) => {
    const url = cleanUrl(values.url);
    const newValue = {
      ...data,
      cookies: [...values.cookies],
      url,
    };
    handleChange(newValue);
  };

  return (
    <Container>
      <InputWrapper>
        <StyledInput
          fullWidth={false}
          label={intl.formatMessage({
            id: 'external-draggable-tab.title.label',
            defaultMessage: 'Title',
          })}
          variant="standard"
          error={Boolean(errors?.title)}
          helperText={errors?.title ?? ' '}
          value={data?.title}
          onChange={updateTitle}
          name={`${name}.title`}
        />
      </InputWrapper>
      <UrlInputWrapper>
        <StyledExpandableInput
          expandable
          label={intl.formatMessage({
            id: 'external-draggable-tab.url.label',
            defaultMessage: 'URL',
          })}
          placeholder="https://example.com"
          variant="standard"
          error={Boolean(errors?.url)}
          helperText={errors?.url ?? ' '}
          value={data?.url}
          onChange={updateUrl}
          name={`${name}.url`}
          InputProps={{
            onKeyPress: handleKeyPress,
          }}
          expandableProps={{
            title: intl.formatMessage({
              id: 'external-draggable-tab.expanded-url.title',
              defaultMessage: 'URL',
            }),
            minimizedInputProps: {
              InputProps: isNoCodeParamsFeatureActive ? { startAdornment: <DynamicUrlToolTip /> } : {},
            },
          }}
        />
      </UrlInputWrapper>
      <AdvancedEditModal
        isOpen={isAdvancedEditModalOpen}
        onClose={() => setIsAdvancedEditModalOpen(false)}
        onSubmit={updateFromAdvancedEditModal}
        url={data?.url}
        cookies={data?.cookies}
      />
      {isAdvancedEditFeatureActive && (
        <CenteredIcon>
          <WithAlignment error={errors}>
            <StyledInfoTooltip
              placement="top"
              title={intl.formatMessage({
                id: 'external-draggable-tab.advanced-edit.info-tooltip',
                defaultMessage: 'Advanced Edit',
              })}
            >
              <div>
                <AdvancedEditIconButton
                  $backgroundColor={isUsingCookies ? theme.palette.primary.medium : 'none'}
                  variant="text"
                  Icon={<Icon IconComponent={otherIcons.edit} />}
                  iconColor={isUsingCookies ? theme.palette.grayScale.medium : theme.palette.primary.dark}
                  onClick={() => setIsAdvancedEditModalOpen(true)}
                />
              </div>
            </StyledInfoTooltip>
          </WithAlignment>
        </CenteredIcon>
      )}
    </Container>
  );
};

export default ExternalDraggableTab;
