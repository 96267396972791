import React from 'react';
import { useIntl } from 'react-intl';
import {
  Separator,
  ModalContent,
  ModalFooter,
  InputFieldWrapper,
  ArrayFieldWrapper,
  KeyValuePairInput,
  SubmitButtonStatus,
  FormikOnError,
  ModalHeader,
} from '@biotmed/base-components';
import { Cookie } from '@biotmed/settings-sdk';
import { Formik } from 'formik';
import { useFeature } from '@biotmed/react-flags-lib';
import {
  Modal,
  UrlContainer,
  UrlTitle,
  StyledInput,
  CookiesContainer,
  CookiesTitle,
  CancelButton,
  SaveButton,
  FooterContainer,
  UrlInputContainer,
  UrlTitleContainer,
} from './AdvancedEditModal.styled';
import { validateAdvancedEditModal } from './validations';
import DynamicUrlToolTip from './DynamicUrlToolTip';

interface CookiesConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  onSubmit: (values: { cookies: Cookie[]; url: string }) => void;
  cookies: Array<Cookie> | undefined;
}

const AdvancedEditModal: React.FC<CookiesConfigurationModalProps> = props => {
  const { isOpen, onClose, url, cookies = [], onSubmit } = props;
  const [submitButtonStatus, setSubmitButtonStatus] = React.useState<SubmitButtonStatus>(SubmitButtonStatus.NORMAL);
  const { isFeatureActive: isNoCodeParamsFeatureActive } = useFeature({ flag: 'NO_CODE_PARAMS' });

  const handleSubmit = (values: { url: string; cookies: Cookie[] }) => {
    setSubmitButtonStatus(SubmitButtonStatus.LOADING);
    onSubmit(values);
    setTimeout(() => setSubmitButtonStatus(SubmitButtonStatus.SUCCESS), 1000);
    setTimeout(() => {
      onCancel();
    }, 1000);
  };

  const onCancel = () => {
    onClose();
    setSubmitButtonStatus(SubmitButtonStatus.NORMAL);
  };

  const intl = useIntl();

  return (
    <Modal open={isOpen}>
      <Formik initialValues={{ url, cookies }} onSubmit={handleSubmit} validationSchema={validateAdvancedEditModal}>
        {({ handleSubmit }) => {
          const submitButtonProps = {
            onClick: () => handleSubmit(),
            submit: true,
            submitButtonProps: {
              loadingLabel: intl.formatMessage({
                id: 'portal-builder.tabs-builder.advanced-edit-modal.save-button.loading',
                defaultMessage: 'Saving',
              }),
              successLabel: intl.formatMessage({
                id: 'portal-builder.tabs-builder.advanced-edit-modal.save-button.success',
                defaultMessage: 'OK',
              }),
              status: submitButtonStatus,
            },
            children: intl.formatMessage({
              id: 'portal-builder.tabs-builder.advanced-edit-modal.save-button',
              defaultMessage: 'OK',
            }),
          };
          return (
            <FormikOnError>
              <ModalHeader
                title={intl.formatMessage({
                  id: 'portal-builder.tabs-builder.advanced-edit-modal.title',
                  defaultMessage: 'Advanced Edit',
                })}
                onClose={onCancel}
              />
              <ModalContent>
                <Separator height="10px" />
                <UrlContainer>
                  <UrlTitleContainer>
                    <UrlTitle>
                      {intl.formatMessage({
                        id: 'portal-builder.tabs-builder.advanced-edit-modal.url',
                        defaultMessage: 'URL',
                      })}
                    </UrlTitle>
                    {isNoCodeParamsFeatureActive && <DynamicUrlToolTip />}
                  </UrlTitleContainer>
                  <UrlInputContainer>
                    <StyledInput minRows={4} maxRows={8} name="url" placeholder="https://example.com" multiline />
                  </UrlInputContainer>
                </UrlContainer>
                <Separator height="1px" />
                <CookiesContainer>
                  <CookiesTitle>
                    {intl.formatMessage({
                      id: 'portal-builder.tabs-builder.advanced-edit-modal.cookies',
                      defaultMessage: 'Cookies',
                    })}
                  </CookiesTitle>
                  <ArrayFieldWrapper<Cookie>
                    name="cookies"
                    initialNewValue={{ name: '', value: '' }}
                    addButtonText={intl.formatMessage({
                      id: 'portal-builder.tabs-builder.advanced-edit-modal.add-cookie',
                      defaultMessage: '+ Add cookie',
                    })}
                    renderInput={(i: number, name: string) => (
                      <KeyValuePairInput
                        renderKeyInput={() => (
                          <InputFieldWrapper name={`${name}.${i}.name`} variant="standard" placeholder="Name" />
                        )}
                        renderValueInput={() => (
                          <InputFieldWrapper name={`${name}.${i}.value`} variant="standard" placeholder="Value" />
                        )}
                      />
                    )}
                  />
                </CookiesContainer>
              </ModalContent>
              <FooterContainer>
                <ModalFooter>
                  <CancelButton onClick={onCancel}>
                    {intl.formatMessage({
                      id: 'portal-builder.tabs-builder.advanced-edit-modal.cancel',
                      defaultMessage: 'Cancel',
                    })}
                  </CancelButton>
                  <SaveButton {...submitButtonProps} submit variant="contained">
                    {intl.formatMessage({
                      id: 'portal-builder.tabs-builder.advanced-edit-modal.save',
                      defaultMessage: 'OK',
                    })}
                  </SaveButton>
                </ModalFooter>
              </FooterContainer>
            </FormikOnError>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AdvancedEditModal;
