import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormikOnError, ModalContent, ModalHeader, SubmitButtonStatus } from '@biotmed/base-components';
import { EmbeddedError } from '@biotmed/system-notifications';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { defineMessages, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useTheme } from 'styled-components';
import { LoginRequestV2 } from '@biotmed/ums-sdk';
import { ResetSystemRequest } from '@biotmed/settings-sdk';
import {
  newPasswordConfirmationYupValidations,
  newPasswordYupValidations,
  useCredentialsValidation,
} from '@biotmed/auth-pages';
import { ValidateUserCredentialsResponse } from '@biotmed/auth-pages/lib/components/PasswordStrength/types';

import intl from '@biotmed/i18n';
import { useFeature } from '@biotmed/react-flags-lib';
import {
  HrWrapper,
  StyledResetSystemModal,
  StyledModalFooter,
  ResetSystemButton,
  ResetSystemModalTitle,
  ResetSystemModalInnerContent,
} from '../EnvironmentConfiguration.styled';
import ResetConfirmation from './ResetConfirmation';
import ResetSystemModalSteps from './ResetSystemModalSteps';
import { NewOwner } from './NewOwner';

interface ResetSystemModalProps {
  isOpen: boolean;
  onCloseClick: () => void;
  containerId: string;
  onNextClick: (values: LoginRequestV2) => void;
  nextButtonStatus: SubmitButtonStatus;
  submitButtonStatus: SubmitButtonStatus;
  onSubmit: (values: ResetSystemRequest) => void;
  modalContentRef: React.MutableRefObject<any>;
}

interface ResetSystemForm {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface ResetConfirmationForm {
  username: string;
  password: string;
}

export interface NewOwnerForm {
  firstName: string;
  lastName: string;
  email: string;
  newPassword: string;
  newPasswordConfirmation: string;
  agree: boolean;
}

const messages = defineMessages({
  required: { id: 'reset-system.modal.validation.required', defaultMessage: 'Required' },
});

const getValidationSchema = (credentialsValidation?: ValidateUserCredentialsResponse) => [
  yup.object().shape({
    username: yup
      .string()
      .trim()
      .required(intl.current.formatMessage(messages.required))
      .email(
        intl.current.formatMessage({
          id: 'reset-system.modal.validation.error.email-not-valid',
          defaultMessage: 'Must be a valid email',
        }),
      ),
    password: yup.string().trim().required(intl.current.formatMessage(messages.required)),
  }),
  yup.object().shape({
    firstName: yup.string().trim().required(intl.current.formatMessage(messages.required)),
    lastName: yup.string().trim().required(intl.current.formatMessage(messages.required)),
    email: yup
      .string()
      .trim()
      .required(intl.current.formatMessage(messages.required))
      .email(
        intl.current.formatMessage({
          id: 'reset-system.modal.validation.error.email-not-valid',
          defaultMessage: 'Must be a valid email',
        }),
      ),
    newPassword: newPasswordYupValidations(credentialsValidation),
    newPasswordConfirmation: newPasswordConfirmationYupValidations('newPassword'),
    agree: yup.bool().oneOf(
      [true],
      intl.current.formatMessage({
        id: 'reset-system.modal.validation.error.check-box',
        defaultMessage: 'The checkbox must be clicked in order to continue',
      }),
    ),
  }),
];

export const ResetSystemModal = (props: ResetSystemModalProps) => {
  const {
    isOpen,
    onCloseClick,
    containerId,
    onNextClick,
    nextButtonStatus,
    submitButtonStatus,
    onSubmit,
    modalContentRef,
  } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [scrollToError, setScrollToError] = useState(false);
  const intl = useIntl();
  const theme = useTheme();

  // WithFlag: PASSWORD_STRENGTH
  const { isFeatureActive: isPasswordStrengthFeatureActive } = useFeature({ flag: 'PASSWORD_STRENGTH' });
  const withPasswordStrength = isPasswordStrengthFeatureActive;
  // FlagOff: PASSWORD_STRENGTH

  const isDisabled =
    stepIndex === 0 ? nextButtonStatus !== SubmitButtonStatus.NORMAL : submitButtonStatus !== SubmitButtonStatus.NORMAL;

  const [passwordStrengthOpen, setPasswordStrengthOpen] = useState<boolean>();

  const {
    credentialsValidation,
    setPassword,
    isLoading: credentialsValidationLoading,
  } = useCredentialsValidation(withPasswordStrength);

  useEffect(() => {
    if (nextButtonStatus === SubmitButtonStatus.SUCCESS) {
      setStepIndex(1);
    }
  }, [nextButtonStatus]);

  useEffect(() => {
    if (!isOpen) {
      setStepIndex(0);
    }
  }, [isOpen]);

  const handleNextClick = async (
    validateForm: (values?: any) => Promise<FormikErrors<ResetSystemForm>>,
    setTouched: (touched: FormikTouched<ResetSystemForm>, shouldValidate?: boolean | undefined) => void,
    loginRequest: LoginRequestV2,
  ) => {
    await validateForm().then((e: any) => {
      setTouched(e);
      if (!Object.keys(e).length) {
        onNextClick(loginRequest);
      } else {
        setScrollToError(true);
      }
    });
  };

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const validationSchema = useMemo(
    () => getValidationSchema(credentialsValidation)[stepIndex],
    [credentialsValidation, stepIndex],
  );

  return (
    <StyledResetSystemModal open={isOpen} onClose={onCloseClick} disableEscapeKeyDown>
      <Formik
        initialValues={{
          username: '',
          password: '',
          firstName: '',
          lastName: '',
          email: '',
          newPassword: '',
          newPasswordConfirmation: '',
          agree: false,
        }}
        validationSchema={validationSchema}
        onSubmit={values =>
          onSubmit({
            primaryAdministrator: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.newPassword,
            },
          })
        }
        validateOnBlur
      >
        {({ handleSubmit, values, handleChange, errors, validateForm, setTouched, isValid, handleBlur, touched }) => (
          <FormikOnError scrollToError={scrollToError} resetScrollToError={resetScrollToError}>
            <ModalHeader isDisabled={isDisabled}>
              <ResetSystemModalTitle>
                <div>
                  {intl.formatMessage({
                    id: 'reset-system.modal.title',
                    defaultMessage: 'Reset to Factory Settings',
                  })}
                </div>
                <div>
                  {intl.formatMessage(
                    {
                      id: 'reset-system.modal.title.steps',
                      defaultMessage: 'Step {stepIndex}/2',
                    },
                    { stepIndex: stepIndex + 1 },
                  )}
                </div>
              </ResetSystemModalTitle>
              <HrWrapper $customPadding="5px 0 0">
                <hr />
              </HrWrapper>
            </ModalHeader>
            <ModalContent isDisabled={isDisabled}>
              <ResetSystemModalInnerContent ref={modalContentRef}>
                <EmbeddedError containerId={containerId} />
                <ResetSystemModalSteps
                  stepIndex={stepIndex}
                  steps={[
                    <ResetConfirmation
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      handleBlur={handleBlur}
                    />,
                    <NewOwner
                      values={values}
                      errors={errors}
                      onPasswordChange={e => {
                        setPassword(e.target.value);
                        handleChange(e);
                        setPasswordStrengthOpen(true);
                      }}
                      onConfirmPasswordChange={handleChange}
                      onAgreeToggle={handleChange}
                      touched={touched}
                      handleBlur={e => {
                        setPasswordStrengthOpen(false);
                        handleBlur(e);
                      }}
                      handlePasswordFocus={e => {
                        setPasswordStrengthOpen(true);
                      }}
                      setTouched={setTouched}
                      passwordStrengthModalProps={{
                        open: passwordStrengthOpen,
                        setOpen: setPasswordStrengthOpen,
                        credentialsValidationLoading,
                        passwordStrength: credentialsValidation?.passwordStrength,
                        success: !!credentialsValidation?.success,
                      }}
                    />,
                  ]}
                />
              </ResetSystemModalInnerContent>
            </ModalContent>
            <StyledModalFooter isDisabled={isDisabled}>
              <Button paddingVertical="15px" onClick={onCloseClick} textColor={theme.palette.grayScale.darker2}>
                {intl.formatMessage({ id: 'reset-system.modal.button.cancel', defaultMessage: 'Cancel' })}
              </Button>
              {stepIndex === 0 && (
                <Button
                  variant="contained"
                  paddingVertical="15px"
                  onClick={() => {
                    handleNextClick(validateForm, setTouched, { username: values.username, password: values.password });
                  }}
                >
                  {intl.formatMessage({ id: 'reset-system.modal.button.next', defaultMessage: 'Next' })}
                </Button>
              )}
              {stepIndex === 1 && (
                <ResetSystemButton
                  submit
                  paddingVertical="15px"
                  color="warning"
                  onClick={() => handleSubmit()}
                  submitButtonProps={{
                    status: submitButtonStatus,
                    loadingLabel: intl.formatMessage({
                      id: 'reset-system.modal.submit.button.loading',
                      defaultMessage: 'Starting Reset',
                    }),
                    successLabel: intl.formatMessage({
                      id: 'reset-system.modal.submit.button.success',
                      defaultMessage: 'Reset Started',
                    }),
                  }}
                >
                  {intl.formatMessage({ id: 'reset-system.modal.submit.button.normal', defaultMessage: 'Start Reset' })}
                </ResetSystemButton>
              )}
            </StyledModalFooter>
          </FormikOnError>
        )}
      </Formik>
    </StyledResetSystemModal>
  );
};

export default ResetSystemModal;
