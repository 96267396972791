import { defineMessages } from 'react-intl';
import { AnalyticsDBInformationResponseActivationStateEnum } from '@biotmed/dms-sdk';

const analyticsDBStatusDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-status',
    defaultMessage: 'Inactive',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-status',
    defaultMessage: 'Initializing the DB may take several hours depending on DB size',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-status',
    defaultMessage: 'Stopping initialization',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-status',
    defaultMessage: 'Active, sync on',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-status',
    defaultMessage: 'Active, sync off',
  },
});

export const getStatusText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBStatusDictionary[syncState];
};

export const TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_ON_FIRST = {
  id: 'analytics-db.tooltip-status-active-and-sync-on-first',
  defaultMessage: `Active and Synchronization On`,
};

export const TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_ON_SECOND = {
  id: 'analytics-db.tooltip-status-active-and-sync-on-second',
  defaultMessage: `Analytics DB is available and is synched with operational DB`,
};

export const TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_OFF_FIRST = {
  id: 'analytics-db.tooltip-status-active-and-sync-off-first',
  defaultMessage: `Active and Synchronization Off`,
};

export const TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_OFF_SECOND = {
  id: 'analytics-db.tooltip-status-active-and-sync-off-second',
  defaultMessage: `Analytics DB is available but information is not currently synced with operational DB and may not be up to date`,
};

export const TOOLTIP_STATUS_TEXT_INACTIVE_FIRST = {
  id: 'analytics-db.tooltip-status-inactive-first',
  defaultMessage: `Inactive`,
};

export const TOOLTIP_STATUS_TEXT_INACTIVE_SECOND = {
  id: 'analytics-db.tooltip-status-inactive-second',
  defaultMessage: `Analytics DB is not available`,
};

export const TOOLTIP_STATUS_TEXT_INITIALIZING_FIRST = {
  id: 'analytics-db.tooltip-status-initializing-first',
  defaultMessage: `Initializing`,
};

export const TOOLTIP_STATUS_TEXT_INITIALIZING_SECOND = {
  id: 'analytics-db.tooltip-status-initializing-second',
  defaultMessage: `Analytics DB is currently being synced with operational DB. Analytics DB is not currently available for querying and will turn “Active and Synchronization On” when initialization is completed`,
};

export const TOOLTIP_STATUS_TEXT_STOPPING_INITIALIZATION_FIRST = {
  id: 'analytics-db.tooltip-status-stopping-initializing-first',
  defaultMessage: `Stopping Initialization`,
};

export const TOOLTIP_STATUS_TEXT_STOPPING_INITIALIZATION_SECOND = {
  id: 'analytics-db.tooltip-status-stopping-initializing-second',
  defaultMessage: `Analytics DB is stopping the initialization process, Analytics DB is not available and will turn “Inactive” when the stop process is completed`,
};

const analyticsDBSyncControlDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-sync-system',
    defaultMessage:
      'Starting the sync will build the analytics DB and sync it with the operational DB. This action may take several hours. The analytics DB will not be available during sync.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-sync-system',
    defaultMessage:
      'Stopping the initialization will force stop the build of the analytics DB. The analytics DB will not be available and will require to start sync again in order to re-build it.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-sync-system',
    defaultMessage:
      'Stopping the initialization will force stop the build of the analytics DB. The analytics DB will not be available and will require to start sync again in order to re-build it.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-sync-system',
    defaultMessage:
      'Stopping the real-time sync of the analytics DB. The Analytics DB will be still available but not up to date.',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-sync-system',
    defaultMessage:
      'Starting the sync will clear the analytics DB and re-sync it with the operational DB. This action may take several hours. The analytics DB will not be available during sync.',
  },
});

export const getSyncControlText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBSyncControlDictionary[syncState];
};

const analyticsDBButtonDictionary = defineMessages({
  [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: {
    id: 'analytics-db.inactive-button-text',
    defaultMessage: 'Start Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: {
    id: 'analytics-db.initializing-button-text',
    defaultMessage: 'Stop Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: {
    id: 'analytics-db.stopping-initializing-button-text',
    defaultMessage: 'Stop Initializing',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: {
    id: 'analytics-db.active-sync-on-button-text',
    defaultMessage: 'Stop Sync',
  },
  [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: {
    id: 'analytics-db.active-sync-off-button-text',
    defaultMessage: 'Start Sync',
  },
});

export const getButtonText = (syncState: AnalyticsDBInformationResponseActivationStateEnum) => {
  return analyticsDBButtonDictionary[syncState];
};
