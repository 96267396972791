import { ForceConfirmationPopup } from './ForceConfirmationPopup';
import getAddForceConfirmationText from './addForceConfirmation/getAddForceConfirmationText';
import getEditForceConfirmationText from './editForeConfirmation/getEditForceConfirmationText';
import getDeleteForceConfirmationText from './deleteForceConfirmation/getDeleteForceConfirmationText';
import useForceConfirmationProps from './hooks/useForceConfirmationProps';
import Information from './Information';

export {
  getAddForceConfirmationText,
  getEditForceConfirmationText,
  getDeleteForceConfirmationText,
  useForceConfirmationProps,
  Information,
};

export type {
  TemplateForceErrorCodesType,
  AddTemplateForceErrorCodesType,
  EditTemplateForceErrorCodesType,
  DeleteTemplateForceErrorCodesType,
} from './types';

export default ForceConfirmationPopup;
