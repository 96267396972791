import styled from 'styled-components';
import { BaseProps, convertTypographySettingsToCss } from '@biotmed/base-components';
import { Chip, Popper } from '@mui/material';

export const TooltipContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  padding: 20px 15px 10px;
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)}
`;

export const TooltipInputWrapper = styled.div`
  height: 50px;
  &&&& input {
    padding-left: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmationButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const StyledTooltipWrapper = styled.div`
  z-index: 10;
`;

export const BasePath = styled.div.attrs(props => ({
  className: 'base-path',
}))<{ $readOnly: boolean }>`
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
  ${props => !props.$readOnly && `color: ${props.theme.palette.grayScale.dark}`}
`;

export const StyledPopper = styled(Popper)`
  min-width: 300px;
  background-color: ${props => props.theme.palette.grayScale.lightest};
  border: ${props => `1px solid ${props.theme.palette.grayScale.medium}`};
  border-radius: 6px;
  box-shadow: ${props => `1px 1px 6px ${props.theme.palette.grayScale.light}`};
  && {
    z-index: ${props => props.theme.zIndex.tooltip};
    margin: 10px;
    padding: 0;

    &[x-placement*='bottom'] .arrow {
      top: -6px;
      border-style: solid none none solid;
    }
    &[x-placement*='top'] .arrow {
      bottom: -6px;
      border-style: none solid solid none;
    }

    &[x-placement*='right'] .arrow {
      left: -6px;
      border-style: none none solid solid;
    }

    &[x-placement*='left'] .arrow {
      right: -6px;
      border-style: solid solid none none;
    }
  }
`;
export const StyledJSONButton = styled(Chip)<{ $disabled: boolean }>`
  &.chipControl {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid
      ${props => (props.$disabled ? props.theme.palette.grayScale.darker : props.theme.palette.primary.medium)};
    border-radius: 30px;
    height: 25px;
    &.chipControl:hover {
      background-color: transparent;
    }
  }
`;

export const StyledJSONIcon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

// eslint-disable-next-line no-shadow
export enum JSONIconTextSizeOptions {
  BIG = 'big',
  SMALL = 'small',
}

interface StyledJSONIconTextProps {
  $size: JSONIconTextSizeOptions;
}
export const StyledJSONIconText = styled.div<StyledJSONIconTextProps>`
  font-size: ${props =>
    props.$size === JSONIconTextSizeOptions.BIG
      ? '1.2rem'
      : '0.8rem'}; // hardCoded because it's an icon special case - approved by Adi
  height: ${props => (props.$size === JSONIconTextSizeOptions.BIG ? '1.2rem' : 0)};
  text-align: center;
`;
export const InputWithTooltipWrapper = styled.div<{ $bold: boolean }>`
  // width: 100%;
  flex: auto;
  cursor: pointer;
  & .rootControl {
    border-radius: 0;
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.grayScale.light};
    }
    .MuiInputBase-adornedEnd {
      background-color: ${props => props.theme.palette.grayScale.light};
    }
    .MuiOutlinedInput-input {
      background-color: ${props => props.theme.palette.grayScale.light};
      ${props =>
        convertTypographySettingsToCss(props.$bold ? props.theme.typography.h7 : props.theme.typography.body1)};
    }
    .MuiOutlinedInput-adornedEnd {
      padding-right: 5px;
    }
  }
`;
export const Arrow = styled.div`
  &.arrow {
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
    background: transparent;
    border-bottom: 0;
  }
`;
