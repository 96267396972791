import intl from '@biotmed/i18n';
import { SelectedTabTypeEnum } from '@biotmed/settings-sdk';
import { defineMessages } from 'react-intl';
import * as Yup from 'yup';

const messages = defineMessages({
  inputIsRequired: {
    id: 'portalBuilder.input.error.is-required',
    defaultMessage: 'Required',
  },
  urlIsNotValid: {
    id: 'portalBuilder.input.error.is-valid-url',
    defaultMessage: 'URL is not valid',
  },
  titleMaxLength: {
    id: 'template.modal.input.error.max-length',
    defaultMessage: 'Title can not be longer than {maxLength} characters',
  },
});

const TITLE_MAX_LENGTH = 128;

const validateRsanFieldByType = Yup.mixed()
  .nullable()
  .when(['type'], {
    is: SelectedTabTypeEnum.ReverseReference,
    then: Yup.object().shape({
      basePath: Yup.string().required(intl.current.formatMessage(messages.inputIsRequired)),
      name: Yup.string()
        .max(
          TITLE_MAX_LENGTH,
          intl.current.formatMessage(messages.titleMaxLength, {
            maxLength: TITLE_MAX_LENGTH,
          }),
        )
        .required(intl.current.formatMessage(messages.inputIsRequired)),
    }),
  });

const validateTemplateFieldByType = Yup.object()
  .nullable()
  .when(['type'], {
    is: SelectedTabTypeEnum.Template,
    then: Yup.object().shape({
      id: Yup.string().required(intl.current.formatMessage(messages.inputIsRequired)),
      title: Yup.string()
        .trim()
        .max(
          TITLE_MAX_LENGTH,
          intl.current.formatMessage(messages.titleMaxLength, {
            maxLength: TITLE_MAX_LENGTH,
          }),
        )
        .required(intl.current.formatMessage(messages.inputIsRequired)),
    }),
  });

export const urlValidationSchema = Yup.string()
  .matches(
    /^(http|https|ftp):\/\/|[a-zA-Z0-9\-.]+.[a-zA-Z](:[a-zA-Z0-9]*)?\?([a-zA-Z0-9\-._?,'/\\+&amp;%$#=~])*[^.,)(\s]$/,
    intl.current.formatMessage(messages.urlIsNotValid),
  )
  .required(intl.current.formatMessage(messages.inputIsRequired));

const validatExternalFieldByType = Yup.object()
  .nullable()
  .when(['type'], {
    is: SelectedTabTypeEnum.ExternalPage,
    then: Yup.object().shape({
      title: Yup.string()
        .trim()
        .max(
          TITLE_MAX_LENGTH,
          intl.current.formatMessage(messages.titleMaxLength, {
            maxLength: TITLE_MAX_LENGTH,
          }),
        )
        .required(intl.current.formatMessage(messages.inputIsRequired)),
      url: urlValidationSchema,
    }),
  });

export const validateAdvancedEditModal = Yup.object().shape({
  url: urlValidationSchema,
  cookies: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().trim().required(intl.current.formatMessage(messages.inputIsRequired)),
      value: Yup.string().trim().required(intl.current.formatMessage(messages.inputIsRequired)),
    }),
  ),
});

export const schemaValidatorObject = Yup.object().shape({
  tabs: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().nullable().required(intl.current.formatMessage(messages.inputIsRequired)),
      externalPage: validatExternalFieldByType,
      reverseReference: validateRsanFieldByType,
      template: validateTemplateFieldByType,
    }),
  ),
});
