import { IconButton, Input } from '@biotmed/base-components';
import styled from 'styled-components';

export const StyledDraggableTab = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const InputsContainer = styled.div`
  display: flex;
  column-gap: 20px;
  flex: 1;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const DeleteButton = styled(IconButton)``;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 15vw;

  &&& .MuiFormHelperText-root {
    margin: 1px 0px;
  }
`;

export const UrlInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  &&& .MuiFormHelperText-root {
    margin: 1px 0px;
  }
`;

export const Container = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  flex: 1;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  .MuiInput-root {
    margin-top: 16px;
    padding: 4.5px;
  }

  && .MuiInput-input {
    // in order to match the design of the autocomplete with the input
    padding: 4px 4px 4px 0px;
  }
`;

export const StyledExpandableInput = styled(Input)`
  width: 100%;
  .MuiInput-root {
    margin-top: 16px;
    padding: 4.5px;
  }

  && .MuiInput-input {
    // in order to match the design of the autocomplete with the input
    padding: 4px 4px 4px 0px;
  }
`;

export const InfoIconContainer = styled.div`
  cursor: default;
`;

export const AdvancedEditIconButton = styled(IconButton)<{ $backgroundColor: string }>`
  &&&& {
    background-color: ${props => props.$backgroundColor};
  }
`;
