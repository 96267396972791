import { getEntityTypeIntlDisplayNamePlural, getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { FieldArray, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import AddFieldButton from 'src/components/AddFieldButton';
import { Hr } from 'src/components/Hr/Hr.styled';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { CHILD_TEMPLATE_FORM_CONTAINER_ID, childrenTemplatesFieldName } from 'src/routes/Templates/modules/constant';
import { TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { useChildrenTemplates } from 'src/routes/Templates/hooks/useChildrenTemplate';
import { FieldError } from '../../../../../components/formikHelpers/FieldError';
import { TemplateMode, EntityTemplateForm } from '../../Template';
import { Title } from '../../Templates.styled';
import ChildrenType from './ChildrenType/ChildrenType';
import useChildren, { emptyRow } from './ChildrenType/useChildren';
import { ChildrenTypesContainer, NoChildrenTypes, Spin, SpinBox } from './ChildrenTypes.styled';

interface ChildrenTypesProps {
  entity: Entity;
}

const ChildrenTypes: React.FC<ChildrenTypesProps> = props => {
  const intl = useIntl();
  const formik = useFormikContext<EntityTemplateForm>();
  const { entity } = props;
  const { values, errors, touched, setFieldValue } = formik;
  const { isLoading: isLoadingChildren } = useChildrenTemplates(values.entityTemplate.id);
  const childrenTypesList = useMemo(() => {
    const childrenTypes = [...values?.[childrenTemplatesFieldName]] ?? [];
    return childrenTypes
      ?.map((childrenType: any, index: number) => ({ childrenTypeObject: childrenType, index }))
      ?.filter(childrenType => childrenType.childrenTypeObject.entityTypeName === entity.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.childrenTemplates]);

  const [openDrawer, closeDrawer, TemplateComponent] = useChildren(entity);

  const addSubmit =
    (push: any) =>
    (
      entityType: string,
      templateId: string,
      childrenTemplateValues: EntityTemplateForm,
      originalChildrenTemplates: any,
      forceUpdate: boolean,
    ) => {
      const customAttributes = childrenTemplateValues.entityTemplate.customAttributes?.map(customAttribute => ({
        ...customAttribute,
        isSaved: true,
      }));
      const childrenTemplate = { ...childrenTemplateValues.entityTemplate, customAttributes };
      push(childrenTemplate);
      closeDrawer();
    };

  const editSubmit =
    (index: string) =>
    (
      entityType: string,
      templateId: string,
      childrenTemplateValues: EntityTemplateForm,
      originalChildrenTemplates: any,
      forceUpdate: boolean,
    ) => {
      const customAttributes = childrenTemplateValues.entityTemplate.customAttributes?.map(customAttribute => ({
        ...customAttribute,
        isSaved: true,
      }));

      setFieldValue(`${childrenTemplatesFieldName}.${index}`, {
        ...childrenTemplateValues.entityTemplate,
        customAttributes,
      });
      closeDrawer();
    };

  return (
    <ChildrenTypesContainer>
      <Title>{`${intl.formatMessage(getEntityTypeIntlDisplayNamePlural(entity.name))}`}</Title>
      <FieldArray name={childrenTemplatesFieldName}>
        {(arrayHelpers: any) => {
          const { push, remove } = arrayHelpers;
          return isLoadingChildren ? (
            <SpinBox>
              <Spin />
            </SpinBox>
          ) : (
            <>
              {childrenTypesList.length ? (
                childrenTypesList?.map((childrenType: any, index: number) => {
                  return (
                    <ChildrenType
                      entityName={entity.name}
                      onClick={(templateOverview?: TemplateOverviewResponse) => {
                        openDrawer(
                          childrenType.childrenTypeObject,
                          TemplateMode.EDIT,
                          editSubmit(childrenType.index),
                          templateOverview,
                        );
                      }}
                      childrenType={childrenType.childrenTypeObject}
                      key={childrenType.index}
                      onDelete={() => {
                        remove(childrenType.index);
                      }}
                    />
                  );
                })
              ) : (
                <NoChildrenTypes>
                  {intl.formatMessage(
                    {
                      id: 'template.modal.children-type.no-children-types',
                      defaultMessage: "You don't have any {childrenTypeName} assigned yet",
                    },
                    {
                      childrenTypeName: intl
                        .formatMessage(getEntityTypeIntlDisplayNamePlural(entity.name))
                        .toLowerCase(),
                    },
                  )}
                </NoChildrenTypes>
              )}
              <FieldError
                errors={errors}
                attributeName={childrenTemplatesFieldName}
                name={childrenTemplatesFieldName}
                touched={touched}
              />
              <Hr />
              <AddFieldButton shadow onClick={() => openDrawer(emptyRow, TemplateMode.ADD, addSubmit(push))}>
                {intl.formatMessage(
                  {
                    id: 'template.modal.add-children-field-button',
                    defaultMessage: '+ Add {childrenTypeName} ',
                  },
                  {
                    childrenTypeName: intl.formatMessage(getEntityTypeIntlDisplayName(entity.name)),
                  },
                )}
              </AddFieldButton>
            </>
          );
        }}
      </FieldArray>
      <TemplateComponent containerId={CHILD_TEMPLATE_FORM_CONTAINER_ID} />
    </ChildrenTypesContainer>
  );
};

export default ChildrenTypes;
