import React, { JSX } from 'react';
import { useIntl } from 'react-intl';
import { Separator } from '@biotmed/base-components';
import { CustomAttribute } from '@biotmed/settings-sdk';
import { ADBLearnMoreLink } from 'src/utils/analyticsDBUtils';
import { useFeature } from '@biotmed/react-flags-lib';
import { useSelector } from 'react-redux';
import { InUseAttributesList, InformationContent } from './ForceConfirmationPopup.styled';
import messages from './dictionaries';
import { selectors as analyticsDBSelector } from '../../routes/ADB';

interface InformationProps {
  errorDetails?: any;
  attributesMapper?: { [key: string]: CustomAttribute };
  withAdbNote?: boolean;
  applyConsequencesMessage: string | JSX.Element | (string | JSX.Element)[];
}

const Information = (props: InformationProps) => {
  const { errorDetails = {}, attributesMapper = {}, withAdbNote = false, applyConsequencesMessage } = props;
  const intl = useIntl();
  const { isFeatureActive: isRevertFeatureActive } = useFeature({ flag: 'FORCE_POPUP_REVERT_BUTTON' });
  const withUndoExplanation = isRevertFeatureActive && errorDetails?.attributes;
  const isAnalyticsDBDeployed = useSelector(analyticsDBSelector.getIsAnalyticsDBDeployed);

  return (
    <>
      {errorDetails?.attributes && (
        <>
          {intl.formatMessage(messages.CHANGED_PROBLEMATIC_ATTRIBUTES)}
          <InUseAttributesList>
            {errorDetails?.attributes?.map((attr: CustomAttribute) => (
              <li>{attributesMapper?.[attr.id]?.name ?? attr.name}</li>
            ))}
          </InUseAttributesList>
        </>
      )}
      <InformationContent>
        <div>{applyConsequencesMessage}</div>
        {withUndoExplanation && (
          <div>
            <Separator height="10px" />
            {intl.formatMessage(messages.UNDO_CONSEQUENCES)}
          </div>
        )}
      </InformationContent>
      {withAdbNote && isAnalyticsDBDeployed && (
        <>
          <Separator height="20px" />
          {intl.formatMessage(messages.ADB_ERROR_NOTE, {
            link: ADBLearnMoreLink,
          })}
        </>
      )}
    </>
  );
};

Information.defaultProps = { attributesMapper: {}, errorDetails: {}, withAdbNote: false };

export default Information;
