import React from 'react';
import { useIntl } from 'react-intl';
import { ConnectionDetailsContainer, ConnectionTitle } from './ADBPage.styled';
import ConnectionDetailField from './ConnectionDetailField';

interface ConnectionDetailsProps {
  databaseName?: string;
  host?: string;
  port?: number;
}

const ConnectionDetails: React.FC<ConnectionDetailsProps> = props => {
  const { databaseName, host, port } = props;
  const intl = useIntl();

  return (
    <ConnectionDetailsContainer>
      <ConnectionTitle>
        {intl.formatMessage({
          id: 'analytics-db.connection-details.title',
          defaultMessage: 'Connection details',
        })}
      </ConnectionTitle>
      <ConnectionDetailField
        fieldName={intl.formatMessage({
          id: 'analytics-db.connection-details.database-name',
          defaultMessage: 'Database name:',
        })}
        fieldValue={databaseName ?? ''}
      />
      <ConnectionDetailField
        fieldName={intl.formatMessage({
          id: 'analytics-db.connection-details.host',
          defaultMessage: 'Host:',
        })}
        fieldValue={host ?? ''}
      />
      <ConnectionDetailField
        fieldName={intl.formatMessage({
          id: 'analytics-db.connection-details.port',
          defaultMessage: 'Port:',
        })}
        fieldValue={(port as unknown as string) ?? ''}
      />
    </ConnectionDetailsContainer>
  );
};

export default ConnectionDetails;
