import { PluginResponseDeploymentPackageTypeEnum } from '@biotmed/settings-sdk';
import { defineMessages } from 'react-intl';

export const deploymentPackageTypeToIntlMessage = defineMessages<PluginResponseDeploymentPackageTypeEnum>({
  [PluginResponseDeploymentPackageTypeEnum.Image]: {
    id: 'plugin-configuration.deploymentPackageType.image',
    defaultMessage: 'Image',
  },
  [PluginResponseDeploymentPackageTypeEnum.Zip]: {
    id: 'plugin-configuration.deploymentPackageType.zip',
    defaultMessage: 'Zip',
  },
});
