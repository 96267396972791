import React from 'react';
import intl from '@biotmed/i18n';
import { CustomAttribute } from '@biotmed/settings-sdk';
import { Information } from 'src/components/forceConfirmation';
import messages from './dictionary';

const editForceObservationsConfirmationProps = (
  errorDetails: any,
  attributesMapper?: { [key: string]: CustomAttribute },
) => {
  return {
    submitLabel: intl.current.formatMessage(messages.TEMPLATE_IN_USE_ERROR_SUBMIT),
    title: intl.current.formatMessage(messages.TEMPLATE_IN_USE_ERROR_TITLE),
    information: () => (
      // TODO: remove unknown
      <Information
        applyConsequencesMessage={intl.current.formatMessage(messages.TEMPLATE_IN_USE_ERROR_CONSEQUENCES_ACTION, {
          bold: ((word: string) => <b>{word}</b>) as unknown as string,
        })}
        errorDetails={errorDetails}
        attributesMapper={attributesMapper}
        withAdbNote
      />
    ),
  };
};

export default editForceObservationsConfirmationProps;
