import { EntityTypeEnum, EntityType } from '@biotmed/data-components';
import { ViewTypeEnum } from '@biotmed/settings-sdk';

const defaultMessagesMapper = {
  [ViewTypeEnum.EntityList]: {
    id: 'ViewType.entity-list',
    defaultMessage: 'List',
  },
  [ViewTypeEnum.TemplateExpand]: {
    id: 'ViewType.template-expand',
    defaultMessage: 'Add, Edit, Expand',
  },
  [ViewTypeEnum.TemplatePreview]: {
    id: 'ViewType.template-preview',
    defaultMessage: 'Preview',
  },
  [ViewTypeEnum.TemplateList]: {
    id: 'ViewType.template-list',
    defaultMessage: 'Template List',
  },
  [ViewTypeEnum.Main]: {
    id: 'ViewType.main',
    defaultMessage: 'Main',
  },
  [ViewTypeEnum.OperationalEntityList]: {
    id: 'ViewType.operational-entity-list',
    defaultMessage: 'Operational list',
  },
  [ViewTypeEnum.OperationalTemplatePreview]: {
    id: 'ViewType.operational-template-preview',
    defaultMessage: 'Operational preview',
  },
};

const patientMessagesMapper = {
  ...defaultMessagesMapper,
  [ViewTypeEnum.OperationalTemplatePreview]: {
    id: 'ViewType.operational-template-preview.patient',
    defaultMessage: 'Patient Monitoring Station - preview',
  },
  [ViewTypeEnum.OperationalEntityList]: {
    id: 'ViewType.operational-entity-list.patient',
    defaultMessage: 'Patient Monitoring Station',
  },
};

const deviceMessagesMapper = {
  ...defaultMessagesMapper,
  [ViewTypeEnum.OperationalTemplatePreview]: {
    id: 'ViewType.operational-template-preview.device',
    defaultMessage: 'Device Operation Center - preview',
  },
  [ViewTypeEnum.OperationalEntityList]: {
    id: 'ViewType.operational-entity-list.device',
    defaultMessage: 'Device Operation Center',
  },
};

const entityTypeToViewTypeDictionary: {
  [key in EntityType]: {
    [key in ViewTypeEnum]: {
      id: string;
      defaultMessage: string;
    };
  };
} = {
  [EntityTypeEnum.DEVICE]: deviceMessagesMapper,
  [EntityTypeEnum.PATIENT]: patientMessagesMapper,
  [EntityTypeEnum.CAREGIVER]: defaultMessagesMapper,
  [EntityTypeEnum.COMMAND]: defaultMessagesMapper,
  [EntityTypeEnum.DEVICE_ALERT]: defaultMessagesMapper,
  [EntityTypeEnum.GENERIC_ENTITY]: defaultMessagesMapper,
  [EntityTypeEnum.MANUFACTURER_USER]: defaultMessagesMapper,
  [EntityTypeEnum.ORGANIZATION]: defaultMessagesMapper,
  [EntityTypeEnum.ORGANIZATION_USER]: defaultMessagesMapper,
  [EntityTypeEnum.PATIENT_ALERT]: defaultMessagesMapper,
  [EntityTypeEnum.REGISTRATION_CODE]: defaultMessagesMapper,
  [EntityTypeEnum.USAGE_SESSION]: defaultMessagesMapper,
};

export const getViewTypeDictionary = (entityType: EntityType) => {
  return entityTypeToViewTypeDictionary[entityType] ?? defaultMessagesMapper;
};
