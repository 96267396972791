import { FormikState, FormikValues } from 'formik';

export const revertFormValues = <V extends FormikValues>(
  updatedFormValues: V,
  initialCustomAttributes: any,
  attributesToRevert: any,
  resetFormFunc: (nextState?: Partial<FormikState<V>> | undefined) => void,
) => {
  const updatedCustomAttributes = [...(updatedFormValues?.entityTemplate?.customAttributes ?? [])];

  if (attributesToRevert && initialCustomAttributes) {
    for (const attribute of attributesToRevert) {
      const initialAttribute = initialCustomAttributes?.find((attr: { id: any }) => attr.id === attribute.id);

      if (initialAttribute) {
        const index = updatedCustomAttributes?.findIndex(attr => attr.id === attribute.id); // finds the original position
        if (index >= 0) {
          updatedCustomAttributes[index] = initialAttribute;
        } else {
          updatedCustomAttributes.push(initialAttribute);
        }
      }
    }
  }

  resetFormFunc({
    values: {
      ...updatedFormValues,
      entityTemplate: {
        ...updatedFormValues.entityTemplate,
        customAttributes: updatedCustomAttributes.sort((a, b) => a.displayName.localeCompare(b.displayName)),
      },
    },
  });
};
