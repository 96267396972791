import { createSlice, createSelector, createAction, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { GetTemplateResponse, SearchRequestV2 } from '@biotmed/sdk-api-provider/lib/types/settings';
import { SubmitButtonStatus } from '@biotmed/base-components';
import { Template } from '../../entity/modules/interfaces';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'template';

const FILTER_TABLE_TEMPLATES = Object.freeze({
  parentTemplateId: { isNull: true },
});

export interface Attribute {
  id: string;
  name: string;
}

interface TemplateState<T> {
  searchRequest: SearchRequestV2;
  freeTextSearch: string;
  allTemplates: any;
  templates: any;
  addEditStatus: SubmitButtonStatus;
  deleteStatus: SubmitButtonStatus;
  isOpen: boolean;
  organizationList?: any;
  loading?: boolean;
  errorDetails: any;
  forceAddEditErrorCode: T | null;
  forceDeleteErrorCode: T | null;
  isForceDeletePopupOpen: boolean;
  isValidationModalOpen: boolean;
}

const templatesAdapter = createEntityAdapter<GetTemplateResponse>({});
const allTemplatesAdapter = createEntityAdapter<GetTemplateResponse>({});

export const getInitialState = <T>(state?: TemplateState<T>): TemplateState<T> => ({
  searchRequest: { limit: 10 },
  templates: templatesAdapter.getInitialState({ templatesTotal: 0 }),
  allTemplates: allTemplatesAdapter.getInitialState({}),
  addEditStatus: SubmitButtonStatus.NORMAL,
  deleteStatus: SubmitButtonStatus.NORMAL,
  isOpen: false,
  loading: false,
  errorDetails: null,
  freeTextSearch: '',
  forceAddEditErrorCode: null,
  forceDeleteErrorCode: null,
  isForceDeletePopupOpen: false,
  isValidationModalOpen: false,
});

/* eslint-disable no-param-reassign */
const initializeForceAddEditErrorState = <T>(state: TemplateState<T>) => {
  state.errorDetails = null;
  state.forceAddEditErrorCode = null;
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onLoadTemplate: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.loading = true;
    },
    onLoadTemplatesSuccess: (
      state,
      action: PayloadAction<{ templatesList: Array<GetTemplateResponse>; templatesTotal: number }>,
    ) => {
      templatesAdapter.setAll(state.templates, action.payload.templatesList);
      state.templates.templatesTotal = action.payload.templatesTotal;
      state.deleteStatus = SubmitButtonStatus.NORMAL;
      state.loading = false;
    },
    onLoadAllTemplatesSuccess: (state, action: PayloadAction<Array<GetTemplateResponse>>) => {
      allTemplatesAdapter.setAll(state.allTemplates, action.payload);
    },
    onLoadTemplatesFail: state => {
      state.loading = false;
    },
    createTemplate: (
      state,
      action: PayloadAction<{
        entityTemplate: Template;
        childrenTemplates?: Template[];
        containerId: string;
        force: boolean;
      }>,
    ) => {
      state.addEditStatus = SubmitButtonStatus.LOADING;
    },
    createTemplateSuccess: state => {
      state.searchRequest = { page: 0, limit: state.searchRequest.limit };
      state.addEditStatus = SubmitButtonStatus.SUCCESS;
    },
    createTemplateFail: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
    },
    createTemplateFailedPartial: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
    },
    editTemplate: (
      state,
      action: PayloadAction<{
        entityType: string;
        templateId: string;
        entityTemplate: Template;
        // TODO MAYBE CONVERT TO UPDATE_TEMPLATE_REQUEST BEFORE SENT TO THE ACTION!!
        childrenTemplates: Template[];
        originalChildrenTemplates: any;
        forceUpdate?: boolean;
        containerId: string;
      }>,
    ) => {
      state.addEditStatus = SubmitButtonStatus.LOADING;
    },
    editTemplateSuccess: state => {
      state.addEditStatus = SubmitButtonStatus.SUCCESS;
    },
    editTemplateFail: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      initializeForceAddEditErrorState(state);
    },
    editTemplateFailedPartial: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      initializeForceAddEditErrorState(state);
    },
    deleteTemplate: (state, action: PayloadAction<any>) => {
      state.deleteStatus = SubmitButtonStatus.LOADING;
    },
    deleteTemplateSuccess: state => {
      state.deleteStatus = SubmitButtonStatus.SUCCESS;
      state.isValidationModalOpen = false;
      state.isForceDeletePopupOpen = false;
    },
    deleteTemplateFailed: state => {
      state.deleteStatus = SubmitButtonStatus.NORMAL;
    },
    openModal: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      state.isOpen = true;
    },
    closeModal: state => {
      state.isOpen = false;
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      initializeForceAddEditErrorState(state);
    },
    updateFreeTextSearch: (state, action: PayloadAction<{ freeTextSearch: string }>) => {
      state.freeTextSearch = action.payload.freeTextSearch;
      state.searchRequest.page = 0;
    },
    updateSearchRequest: (state, action: PayloadAction<SearchRequestV2>) => {
      state.searchRequest = action.payload;
    },
    resetSearchRequest: state => {
      state.searchRequest = getInitialState().searchRequest;
      state.freeTextSearch = getInitialState().freeTextSearch;
    },
    onForceAddEditError: <T>(
      state: { forceAddEditErrorCode: T; errorDetails: any },
      action: PayloadAction<{ errorCode: T; errorDetails: any }>,
    ) => {
      state.forceAddEditErrorCode = action.payload.errorCode;
      state.errorDetails = action.payload.errorDetails;
    },
    onForceDeleteError: <T>(
      state: { forceDeleteErrorCode: T; errorDetails: any; isForceDeletePopupOpen: boolean },
      action: PayloadAction<{ errorCode: T; errorDetails: any }>,
    ) => {
      state.forceDeleteErrorCode = action.payload.errorCode;
      state.errorDetails = action.payload.errorDetails;
      state.isForceDeletePopupOpen = true;
    },
    onForceDeleteApprove: state => {
      state.isForceDeletePopupOpen = false;
    },
    onForceAddEditCancel: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      initializeForceAddEditErrorState(state);
    },
    onForceDeleteCancel: state => {
      state.deleteStatus = SubmitButtonStatus.NORMAL;
      state.isValidationModalOpen = false;
      state.isForceDeletePopupOpen = false;
    },
    onClickDeleteTemplate: state => {
      state.isValidationModalOpen = true;
    },
    onCloseValidationModal: state => {
      state.isValidationModalOpen = false;
      state.deleteStatus = SubmitButtonStatus.NORMAL;
    },
    onForceRevert: state => {
      state.addEditStatus = SubmitButtonStatus.NORMAL;
      initializeForceAddEditErrorState(state);
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getTemplatesTotal: createSelector(getState, state => state.templates.templatesTotal),
  selectFreeTextSearch: createSelector(getState, state => state.freeTextSearch),
  selectSearchRequest: createSelector(getState, state => ({
    ...state.searchRequest,
    filter: { ...state.searchRequest.filter, ...FILTER_TABLE_TEMPLATES },
    freeTextSearch: state.freeTextSearch,
  })),
  selectPageLimit: createSelector(getState, state => state.searchRequest.limit ?? 0),
  selectCurrentPage: createSelector(getState, state => state.searchRequest.page ?? 0),
  getTemplatesList: createSelector(getState, state => templatesAdapter.getSelectors().selectAll(state.templates)),
  getAllTemplatesListByEntityType: (entityType?: string) =>
    createSelector(getState, state =>
      allTemplatesAdapter
        .getSelectors()
        .selectAll(state.allTemplates)
        .filter((template: GetTemplateResponse) => template.entityTypeName === entityType),
    ),
  getTemplateById: (templateId: string) =>
    createSelector(getState, state => templatesAdapter.getSelectors().selectById(state.templates, templateId)),
  getAddEditStatus: createSelector(getState, state => state.addEditStatus),
  getDeleteStatus: createSelector(getState, state => state.deleteStatus),
  getIsOpen: createSelector(getState, state => state.isOpen),
  selectLoading: createSelector(getState, state => state.loading),
  getErrorDetails: createSelector(getState, state => state.errorDetails),
  getForceAddEditErrorCode: createSelector(getState, state => state.forceAddEditErrorCode),
  getForceDeleteErrorCode: createSelector(getState, state => state.forceDeleteErrorCode),
  getIsForceDeletePopupOpen: createSelector(getState, state => state.isForceDeletePopupOpen),
  getIsValidationModalOpen: createSelector(getState, state => state.isValidationModalOpen),
};

const extraActions = {
  onLoadAllTemplate: createAction(`${STATE_KEY}/onLoadAllTemplate`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
