import { all, call, put, race, take, takeLatest, delay, select } from 'redux-saga/effects';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { ErrorTypeEnum, errorNotice } from '@biotmed/system-notifications';
import {
  AnalyticsDBConnectionDetailsResponse,
  AnalyticsDBInformationResponse,
  AnalyticsDBInformationResponseDeploymentStateEnum,
} from '@biotmed/dms-sdk';
import { visibilityActions } from '@biotmed/base-components';
import Flags from '@biotmed/flags-lib';
import { actions as loginActions } from '../../../redux/data/login';
import { actions, selectors } from './slice';

function* fetchAnalyticsDBInformation() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsDBStateApi.getAnalyticsDBState,
    );
    yield put(actions.onFetchAnalyticsDBInformationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to load ADB information. with error: ', error);
  }
}

function* fetchAnalyticsDBConnectionDetails() {
  try {
    const response: AxiosResponse<AnalyticsDBConnectionDetailsResponse> = yield call(
      getSdkApi().dms.analyticsDBConnectionApi.getAnalyticsDBConnectionDetails,
    );
    yield put(actions.onFetchAnalyticsDBConnectionDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to load ADB connection details. with error: ', error);
  }
}

function* fetchAnalyticsDBInformationAndConnectionDetails(): any {
  // WithFlag: ANALYTICS_DB_PAGE
  const adbFlag = yield call([Flags, Flags.getFeatureValue], 'ANALYTICS_DB_PAGE');
  if (adbFlag) {
    yield call(fetchAnalyticsDBInformation);
    const isAnalyticsDBDeployed = yield select(selectors.getIsAnalyticsDBDeployed);
    if (isAnalyticsDBDeployed) {
      yield call(fetchAnalyticsDBConnectionDetails);
    }
  }
  // FlagOff: ANALYTICS_DB_PAGE
}

function* startAnalyticsDBInitialization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsDBStateApi.startAnalyticsDBInitialization,
    );
    yield put(actions.onStartAnalyticsDBInitializationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to start ADB initialization. with error: ', error);
  }
}

function* stopAnalyticsDBInitialization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsDBStateApi.stopAnalyticsDBInitialization,
    );
    yield put(actions.onStopAnalyticsDBInitializationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to stop ADB initialization. with error: ', error);
  }
}

function* stopAnalyticsDBSynchronization() {
  try {
    const response: AxiosResponse<AnalyticsDBInformationResponse> = yield call(
      getSdkApi().dms.analyticsDBStateApi.stopAnalyticsDBSynchronization,
    );
    yield put(actions.onStopAnalyticsDBSynchronizationSuccess(response.data));
  } catch (error: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: error?.response?.data || error } }));
    console.error('Failed to stop ADB synchronization. with error: ', error);
  }
}

function* handleMount() {
  yield race({
    pollingAnalyticsDBInfo: call(pollingAnalyticsDBInformationWhenAppVisible),
    unmount: take(actions.onUnmount),
  });
}

function* pollingAnalyticsDBInformationWhenAppVisible() {
  for (;;) {
    const { appHidden } = yield race({
      polling: call(pollingAnalyticsDBInformation),
      appHidden: take(visibilityActions.appHidden),
    });

    if (appHidden) {
      yield take(visibilityActions.appVisible);
    } else {
      break;
    }
  }
}

function* pollingAnalyticsDBInformation() {
  while (true) {
    yield call(fetchAnalyticsDBInformation);
    yield delay(10 * 1000);
  }
}

export default function* watchEntityActions(): Generator<any, void, unknown> {
  yield all([
    takeLatest(loginActions.loginSuccess, fetchAnalyticsDBInformationAndConnectionDetails),
    takeLatest(actions.onFetchAnalyticsDBInformation, fetchAnalyticsDBInformation),
    takeLatest(actions.onStartInitialization, startAnalyticsDBInitialization),
    takeLatest(actions.onStopInitialization, stopAnalyticsDBInitialization),
    takeLatest(actions.onStopSynchronization, stopAnalyticsDBSynchronization),
    takeLatest(actions.onMount, handleMount),
  ]);
}
