import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import {
  Button,
  ButtonProps,
  DrawerModal,
  FormikOnError,
  ModalFooter,
  ModalHeader,
  scrollToTop,
  SubmitButtonStatus,
} from '@biotmed/base-components';
import { EmbeddedError } from '@biotmed/system-notifications';

import { ModalProps } from './types';
import {
  ModalContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  Steps,
  StyledModalContent,
  Title,
} from './Modals.styled';
import { SubmitButton, TitleRow } from './AddModal.styled';

const AddModal = <V extends FormikValues>(props: ModalProps<V>) => {
  const {
    handleSubmit,
    initialValues,
    handleClose,
    renderTitle,
    renderTopContent,
    steps,
    containerId,
    isOpen,
    submitButtonStatus,
    modalWidthDiff,
    additionalFormProps,
    additionalValidationData,
    isDisabled = false,
    renderPageSubmitButton = ({ buttonElement }) => buttonElement,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const [scrollToError, setScrollToError] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const modalContentRef = useRef<HTMLInputElement>(null);
  const isModalDisabled = submitButtonStatus !== SubmitButtonStatus.NORMAL || isDisabled;

  const stepsCount = steps?.length || 0;
  const isLastPage = pageIndex === stepsCount - 1;
  const isFirstPage = pageIndex === 0;

  const resetModal = () => {
    setPageIndex(0);
  };

  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  const resetScrollToError = () => {
    setScrollToError(false);
  };

  const onChangePage = async (
    validateForm: (values?: any) => Promise<FormikErrors<V>>,
    setTouched: (touched: FormikTouched<V>, shouldValidate?: boolean | undefined) => void,
    followUpFunction: () => void,
  ) => {
    await validateForm().then((e: any) => {
      setTouched(e);
      if (!Object.keys(e).length) {
        followUpFunction();
      } else {
        setScrollToError(true);
      }
    });
  };

  const handleOnClickNext = () => {
    setPageIndex(currentPageIndex => currentPageIndex + 1);
    scrollToTop(modalContentRef);
  };

  const handleOnClickPrev = () => {
    setPageIndex(currentPageIndex => currentPageIndex - 1);
    scrollToTop(modalContentRef);
  };

  return (
    <DrawerModal disableEnforceFocus open={isOpen} variant="halfScreen" widthDiff={modalWidthDiff}>
      <Formik
        initialValues={initialValues}
        validationSchema={steps?.[pageIndex]?.validations(additionalValidationData)}
        onSubmit={handleSubmit}
        validateOnBlur
        {...additionalFormProps}
      >
        {({ handleSubmit: formikHandleSubmit, validateForm, setTouched, errors, values, ...rest }) => {
          const submitButtonProps = {
            onClick: () => formikHandleSubmit(),
            submit: true,
            submitButtonProps: {
              loadingLabel: intl.formatMessage({
                id: 'console.add.modal.footer.save-button.loading',
                defaultMessage: 'Creating',
              }),
              successLabel: intl.formatMessage({
                id: 'console.add.modal.footer.save-button.success',
                defaultMessage: 'Created',
              }),
              status: submitButtonStatus,
            },
            children: intl.formatMessage({
              id: 'console.add.modal.footer.save-button',
              defaultMessage: 'Create',
            }),
          };

          const nextButtonProps = {
            onClick: () => {
              onChangePage(validateForm, setTouched, handleOnClickNext);
            },
            children: intl.formatMessage({
              id: 'console.add.modal.footer.next-button',
              defaultMessage: 'Next',
            }),
          };

          const pageSubmitButtonProps: ButtonProps = {
            paddingHorizontal: '32px',
            paddingVertical: '15px',
            variant: 'contained',
            ...(isLastPage ? submitButtonProps : nextButtonProps),
          };

          return (
            <FormikOnError scrollToError={scrollToError} resetScrollToError={resetScrollToError}>
              <ModalContainer>
                <ModalHeader>
                  <ModalHeaderContainer>
                    {containerId && <EmbeddedError containerId={containerId} />}
                    {/* REVIEWER: not sure about this part. maybe Title should be recieved with renderTitle? */}
                    <TitleRow>
                      <Title>{renderTitle()}</Title>
                      <Steps>
                        {intl.formatMessage(
                          { id: 'console.add.modal.header-steps', defaultMessage: 'Step {pageIndex}/{stepsCount}' },
                          { pageIndex: pageIndex + 1, stepsCount },
                        )}
                      </Steps>
                    </TitleRow>
                  </ModalHeaderContainer>
                </ModalHeader>

                <StyledModalContent isDisabled={isModalDisabled}>
                  <ModalContentContainer ref={modalContentRef}>
                    {renderTopContent?.()}
                    {steps[pageIndex].render()}
                  </ModalContentContainer>
                </StyledModalContent>

                <ModalFooter isDisabled={isModalDisabled}>
                  <ModalFooterContainer>
                    {!isFirstPage && (
                      <Button
                        bold
                        textColor={theme.palette.grayScale.darkest}
                        size="large"
                        onClick={() => onChangePage(validateForm, setTouched, handleOnClickPrev)}
                      >
                        &lt;
                        {intl.formatMessage({
                          id: 'console.add.modal.footer.back-button',
                          defaultMessage: 'Back',
                        })}
                      </Button>
                    )}
                    <Button
                      paddingHorizontal="32px"
                      paddingVertical="15px"
                      textColor={theme.palette.grayScale.darker2}
                      size="large"
                      variant="text"
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'console.add.modal.footer.cancel-button',
                        defaultMessage: 'Cancel',
                      })}
                    </Button>
                    {renderPageSubmitButton({
                      onClick: pageSubmitButtonProps.onChange,
                      buttonElement: <SubmitButton {...pageSubmitButtonProps} />,
                      buttonProps: pageSubmitButtonProps,
                      buttonPurpose: isLastPage ? 'submit' : 'next',
                      values,
                    })}
                  </ModalFooterContainer>
                </ModalFooter>
              </ModalContainer>
            </FormikOnError>
          );
        }}
      </Formik>
    </DrawerModal>
  );
};

export default AddModal;
