import React from 'react';
import { Icon, InfoTooltip, Link, otherIcons } from '@biotmed/base-components';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import APP_CONFIG from 'src/config/AppConfig';
import { InputAdornment } from '@mui/material';
import { InfoIconContainer } from './DraggableTab.styled';

interface DynamicUrlToolTipProps {}

const DynamicUrlToolTip = (props: DynamicUrlToolTipProps) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <InputAdornment position="end" disablePointerEvents={false}>
      <InfoTooltip
        title={intl.formatMessage(
          {
            id: 'external-draggable-tab.url.info-tooltip',
            defaultMessage:
              'You can build a dynamic URL that includes contextual parameters like the logged in user and the subject entity. {learnMoreLink}',
          },
          {
            learnMoreLink: (
              <Link
                onClick={() => {
                  window.open(APP_CONFIG.NO_CODE_PARAMETERS_LEARN_MORE_URL);
                }}
              >
                {intl.formatMessage({
                  id: 'external-draggable-tab.url.info-tooltip.link',
                  defaultMessage: 'Learn more',
                })}
              </Link>
            ),
          },
        )}
      >
        <InfoIconContainer>
          <Icon IconComponent={otherIcons.info} color={theme.palette.grayScale.dark} height="16px" />
        </InfoIconContainer>
      </InfoTooltip>
    </InputAdornment>
  );
};

export default DynamicUrlToolTip;
