import {
  Button,
  InfoTooltip,
  InputFieldWrapper,
  ModalHeader,
  PopupModal,
  convertTypographySettingsToCss,
} from '@biotmed/base-components';
import styled from 'styled-components';

export const Modal = styled(PopupModal)`
  && {
    .MuiDialog-paper {
      height: 80vh;
      width: 50vw;
      max-width: 600px;
    }
  }
`;

export const UrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const UrlTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UrlTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const UrlInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CookiesTitle = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.h7)};
`;

export const StyledInput = styled(InputFieldWrapper)`
  width: 100%;
  height: 100%;

  .MuiInput-root {
    overflow: overlay;
  }

  .MuiInputBase-input {
    display: flex;
    max-height: 150px;
    height: 100px;
    overflow: auto;
    padding: 10px;
  }

  .MuiInputBase-root {
    padding: 0px;
  }
`;

export const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)``;

export const CancelButton = styled(StyledButton)`
  &&& {
    color: ${props => props.theme.palette.grayScale.darker};
    ${props => convertTypographySettingsToCss(props.theme.typography.button.big)}
  }
`;

export const SaveButton = styled(StyledButton)`
  &&& {
    ${props => convertTypographySettingsToCss(props.theme.typography.button.big)}
  }
`;

export const FooterContainer = styled.div`
  padding: 10px 20px;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  .MuiButtonBase-root {
    width: 26px;
    height: 26px;
  }
`;

export const CenteredIcon = styled.div`
  display: flex;
  align-items: center;
`;
