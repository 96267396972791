import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Avatar, Button, Column, SidePreview } from '@biotmed/base-components';
import {
  AttributeTypeEnum,
  createCustomFilter,
  createTablePageComponent,
  MoreActionsButton,
} from '@biotmed/data-components';
import {
  FilterV2,
  OrderOrderEnum,
  PluginResponse,
  PluginResponseDeploymentPackageTypeEnum,
  PluginResponseEnabledStateEnum,
  SearchRequestV2,
} from '@biotmed/settings-sdk';

import { deploymentPackageTypeToIntlMessage } from 'src/utils/deploymentPackageTypeToIntlMessage';
import { ReactComponent as PluginIcon } from '../../../images/plugin.svg';
import { mapStateToOppositeState } from '../mappers';
import { actions } from '../modules';
import { actions as readActions, selectors as readSelectors } from '../modules/slice/readSlice';
import { extractId, formatDate } from '../utils';
import PluginDetailsDisplay from './PluginDetailsDisplay';
import PluginAddModal from './PluginAddModal';
import PluginEditModal from './PluginEditModal';

interface PluginConfigurationProps {}

const PluginComponent = createTablePageComponent<PluginResponse, FilterV2, OrderOrderEnum, SearchRequestV2>({
  slice: { actions: readActions, selectors: readSelectors },
  extractId,
});

const PluginConfiguration: React.FC<PluginConfigurationProps> = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // const onLearnMoreClick = () => {
  //   window.open(AppConfig.PLUGIN_CONFIGURATION_LEARN_MORE_URL);
  // };

  const deploymentPackageTypeColumnSelectableValues =
    Object.values(PluginResponseDeploymentPackageTypeEnum)?.map(value => {
      return {
        name: value,
        displayName: intl.formatMessage(deploymentPackageTypeToIntlMessage[value]),
      };
    }) || [];

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-display-name',
          defaultMessage: 'Display Name',
        }),
        dataIndex: 'displayName',
        key: 'displayName',
        sorter: true,
        filterDropdown: createCustomFilter(AttributeTypeEnum.Label, {}, ''),
      },
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-version',
          defaultMessage: 'Version',
        }),
        dataIndex: 'version',
        key: 'version',
        sorter: true,
        filterDropdown: createCustomFilter(AttributeTypeEnum.Integer, {}, ''),
        render: (value: string, record: PluginResponse, index: number) => {
          return (
            <Column width="100px" key={`version-${index}`}>
              {value}
            </Column>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-lastModifiedTime',
          defaultMessage: 'Last Modified Time',
        }),
        dataIndex: 'lastModifiedTime',
        key: 'lastModifiedTime',
        sorter: true,
        filterDropdown: createCustomFilter(AttributeTypeEnum.DateTime, {}, ''),
        render: (value: string, record: PluginResponse, index: number) => {
          return <span key={`lastModifiedDate-${index}`}>{formatDate(value)}</span>;
        },
      },
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-creationTime',
          defaultMessage: 'Creation Time',
        }),
        dataIndex: 'creationTime',
        key: 'creationTime',
        sorter: true,
        filterDropdown: createCustomFilter(AttributeTypeEnum.DateTime, {}, ''),
        render: (value: string, record: PluginResponse, index: number) => {
          return <span key={`creationTime-${index}`}>{formatDate(value)}</span>;
        },
      },
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-deploymentPackageType',
          defaultMessage: 'Deployment Package Type',
        }),
        dataIndex: 'deploymentPackageType',
        key: 'deploymentPackageType',
        sorter: true,
        filterDropdown: createCustomFilter(
          AttributeTypeEnum.MultiSelect,
          { selectableValues: deploymentPackageTypeColumnSelectableValues },
          '',
        ),
        render: (value: string, record: PluginResponse, index: number) => {
          return (
            <Column width="100px" key={`deploymentPackageType-${index}`}>
              {intl.formatMessage(
                deploymentPackageTypeToIntlMessage[value as PluginResponseDeploymentPackageTypeEnum],
              ) ?? value}
            </Column>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: 'plugin-configuration.column-cronSchedule',
          defaultMessage: 'Cron Schedule',
        }),
        dataIndex: 'cronSchedule',
        key: 'cronSchedule',
        sorter: false,
        render: (value: string, record: PluginResponse, index: number) => {
          return (
            <Column width="150px" key={`cronSchedule-${index}`}>
              {value}
            </Column>
          );
        },
      },
      {
        title: '',
        key: 'enabledStateActionColumn',
        dataIndex: 'enabledState',
        align: 'right' as any,
        render: (value: PluginResponseEnabledStateEnum, record: PluginResponse, index: number) => {
          return (
            <MoreActionsButton
              renderActions={(TriggerComponent, onClose) => () => {
                const oppositeStateDetails = mapStateToOppositeState(value);
                return [
                  <TriggerComponent
                    key={`enabledState-TriggerComponent-${index}`}
                    text={oppositeStateDetails.text}
                    Logo={oppositeStateDetails.logo}
                    onClick={(event: any) => {
                      dispatch(
                        actions.onEnableStateUpdate({
                          name: record.name,
                          enabledState: oppositeStateDetails.state,
                        }),
                      );
                      onClose?.();
                    }}
                  />,
                ];
              }}
              variant="horizontal"
            />
          );
        },
        fixed: 'right' as any,
      },
    ],
    [],
  );

  return (
    <>
      <PluginComponent
        withSearchbox
        withActions
        renderActions={() => <Button onClick={() => setIsAddModalOpen(true)}>ADD</Button>}
        withPreview
        renderPreview={selectedRow => {
          // FlagOff: PLUGIN_CONFIGURATION_PAGE-V2 TODO: SOFT-6631 remove from here after implementing the modal
          return (
            <SidePreview
              withDelete={false}
              withExpand
              renderExpand={() => <Button onClick={() => setIsEditModalOpen(true)}>EXPAND</Button>}
              displaySidePreview={!!selectedRow?.name}
              name={selectedRow?.displayName}
              renderAvatar={() => <Avatar IconItem={PluginIcon} />}
              collapsibleSidePanelProps={{ disabled: true }}
            >
              <PluginDetailsDisplay selectedRow={selectedRow} />
            </SidePreview>
          );
        }}
        title={intl.formatMessage({ id: 'pluginConfiguration.mainTitle', defaultMessage: 'Plugin Configuration' })}
        columns={columns}
      />
      <PluginAddModal isOpen={isAddModalOpen} />
      <PluginEditModal isOpen={isEditModalOpen} />
    </>
  );
};

export default PluginConfiguration;
