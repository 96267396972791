import { getSdkApi } from '@biotmed/sdk-api-provider';
import { GetPortalViewsResponse, GetViewResponse } from '@biotmed/settings-sdk';
import { errorNotice, ErrorTypeEnum, successNotice } from '@biotmed/system-notifications';
import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { CONTAINER_ID } from '../components/PortalBuilder';
import { PortalBuilderErrorDictionary, valuesFormatterDictionary } from './dictionaries';
import { actions } from './slice';

function* onLoadViews(action: ReturnType<typeof actions.onLoadViews>): any {
  try {
    const response: AxiosResponse<GetPortalViewsResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.getViewsByPortalType,
      action.payload.portalType,
    );
    const viewsList = response.data.views || [];
    yield put(actions.onLoadViewsSuccess(viewsList));
  } catch (e) {
    console.error(e);
  }
}

function* onLoadSelectedView(action: ReturnType<typeof actions.onLoadSelectedView>): any {
  try {
    const response: AxiosResponse<GetViewResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.getView,
      action.payload.portalType,
      action.payload.viewId,
    );

    yield put(actions.onLoadSelectedViewSuccess(response.data));
    yield put(
      successNotice({
        containerId: CONTAINER_ID,
      }),
    );
  } catch (e: any) {
    console.error(e);
    yield put(actions.onLoadSelectedViewFail());
    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        errorParams: {
          error: e?.response?.data || e,
          dictionary: PortalBuilderErrorDictionary,
          detailsFormatterDictionary: valuesFormatterDictionary,
        },
        containerId: CONTAINER_ID,
      }),
    );
  }
}

// Pay attention that we pass arrayId in tabs.selected although it is not part of the SelectedTab.
// arrayId is for FE purpose
function* onUpdateView(action: ReturnType<typeof actions.onUpdateView>): any {
  const { portalType, viewId, attributes } = action.payload;
  try {
    const response: AxiosResponse<GetViewResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.updateView,
      portalType,
      viewId,
      attributes,
    );
    yield put(actions.onUpdateViewSuccess(response.data));
    yield put(
      successNotice({
        containerId: CONTAINER_ID,
      }),
    );
  } catch (e: any) {
    console.error(e);
    yield put(actions.onUpdateViewFail());
    yield put(
      errorNotice({
        type: ErrorTypeEnum.EMBEDDED,
        errorParams: {
          error: e?.response?.data || e,
          dictionary: PortalBuilderErrorDictionary,
          detailsFormatterDictionary: valuesFormatterDictionary,
        },
        containerId: CONTAINER_ID,
      }),
    );
  }
}

function* onUpdateViewSuccess() {
  yield delay(2000);
  yield put(actions.afterUpdateViewSuccess());
}
export default function* watchEntityActions() {
  yield all([
    takeLatest(actions.onLoadViews, onLoadViews),
    takeLatest(actions.onLoadSelectedView, onLoadSelectedView),
    takeLatest(actions.onUpdateView, onUpdateView),
    takeLatest(actions.onUpdateViewSuccess, onUpdateViewSuccess),
  ]);
}
