import { defineMessages } from 'react-intl';

const messages = defineMessages({
  TEMPLATE_IN_USE_ERROR_TITLE: {
    id: 'templates.edit.in-use-fields.title',
    defaultMessage: 'Delete All Observations?',
  },
  TEMPLATE_IN_USE_ERROR_SUBMIT: {
    id: 'templates.edit.in-use-fields.submit',
    defaultMessage: 'Proceed and Delete Observation Data',
  },
  TEMPLATE_IN_USE_ERROR_CONTENT: {
    id: 'templates.edit.in-use-fields.content',
    defaultMessage: 'You have changed/added/removed the following observation attributes:',
  },
  TEMPLATE_IN_USE_ERROR_CONSEQUENCES_ACTION: {
    id: 'templates.edit.in-use-fields.consequences-action',
    defaultMessage:
      "If you choose to apply these changes <bold>ALL</bold> observation data will be deleted (including observations that were not changed). To apply select 'Proceed and Delete Observation Data'",
  },
  TEMPLATE_IN_USE_ERROR_NOTE: {
    id: 'templates.edit.in-use-fields.note',
    defaultMessage:
      'Note: In case you are using the analytics DB this operation will stop any active sync and a full re-sync will be needed in order to continue working with the analytics DB. For implication of resync please refer to the following article {link}',
  },
});

export default messages;
