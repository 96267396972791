import React, { PropsWithChildren } from 'react';
import { ErrorAlignment } from '@biotmed/base-components';

const WithAlignment = (props: PropsWithChildren<{ error: boolean }>) => {
  const { children, error } = props;

  return (
    <ErrorAlignment error={error}>
      <div style={{ visibility: 'hidden' }}>-</div>
      {children}
    </ErrorAlignment>
  );
};

export default WithAlignment;
