import {
  AnalyticsDBConnectionDetailsResponse,
  AnalyticsDBInformationResponse,
  AnalyticsDBInformationResponseActivationStateEnum,
  AnalyticsDBInformationResponseDeploymentStateEnum,
} from '@biotmed/dms-sdk';
import { PayloadAction, createAction, createSelector, createSlice } from '@reduxjs/toolkit';

export const STATE_KEY = 'analyticsDB';

interface ConnectionDetails {
  host?: string;
  port?: number;
  databaseName?: string;
}
interface AnalyticsDBState {
  activationState?: AnalyticsDBInformationResponseActivationStateEnum;
  deploymentState?: AnalyticsDBInformationResponseDeploymentStateEnum;
  connectionDetails: ConnectionDetails;
}

export const getInitialState = (state?: AnalyticsDBState): AnalyticsDBState => ({
  activationState: state?.activationState ?? AnalyticsDBInformationResponseActivationStateEnum.Inactive,
  deploymentState: state?.deploymentState ?? AnalyticsDBInformationResponseDeploymentStateEnum.NotDeployed,
  connectionDetails: state?.connectionDetails ?? {},
});

/* eslint-disable no-param-reassign */
const updateStateHelper = (state: AnalyticsDBState, action: PayloadAction<AnalyticsDBInformationResponse>) => {
  state.activationState = action.payload.activationState;
  state.deploymentState = action.payload.deploymentState;
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    onFetchAnalyticsDBInformationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onFetchAnalyticsDBConnectionDetailsSuccess: (
      state,
      action: PayloadAction<AnalyticsDBConnectionDetailsResponse>,
    ) => {
      const { host, port, databaseName } = action.payload;
      state.connectionDetails = { host, port, databaseName };
    },
    onStartAnalyticsDBInitializationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onStopAnalyticsDBInitializationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
    onStopAnalyticsDBSynchronizationSuccess: (state, action: PayloadAction<AnalyticsDBInformationResponse>) => {
      updateStateHelper(state, action);
    },
  },
});

const extraActions = {
  onStartInitialization: createAction(`${STATE_KEY}/onStartInitialization`),
  onStopInitialization: createAction(`${STATE_KEY}/onStopInitialization`),
  onStopSynchronization: createAction(`${STATE_KEY}/onStopSynchronization`),
  onFetchAnalyticsDBInformation: createAction(`${STATE_KEY}/onFetchAnalyticsDBInformation`),
  onMount: createAction(`${STATE_KEY}/onMount`),
  onUnmount: createAction(`${STATE_KEY}/onUnmount`),
};

export const actions = { ...slice.actions, ...extraActions };

const getState = (state: { [STATE_KEY]: AnalyticsDBState }) => state[STATE_KEY] || getInitialState();

const getActivationState = createSelector(getState, state => state.activationState);
const getDeploymentState = createSelector(getState, state => state.deploymentState);
const getConnectionDetails = createSelector(getState, state => state.connectionDetails);
const getIsAnalyticsDBDeployed = createSelector(
  [getDeploymentState],
  deploymentState => deploymentState === AnalyticsDBInformationResponseDeploymentStateEnum.Deployed,
);

export const selectors = { getActivationState, getDeploymentState, getConnectionDetails, getIsAnalyticsDBDeployed };

const { reducer } = slice;
export default reducer;
