import React from 'react';
import { PlatformsPopper } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import UmsLogic from '@biot/ums-js-logic';
import AppConfig from 'src/config/AppConfig';
import { useTheme } from 'styled-components';
import { addLocaleToSearchParam } from '@biotmed/i18n';
import { useFeature } from '@biotmed/react-flags-lib';
import { isValidUrl } from 'src/utils/urlUtils';
import { useSelector } from 'react-redux';
import { cookiesManager } from '@biotmed/utils';
import { selectors as analyticsDBSelector } from '../../../../routes/ADB';

interface PlatformsLinksMenuProps {}
type PlatformsType = {
  manufacturer: {
    label: string;
    link: string;
  };
  organization: {
    label: string;
    link: string;
  };
  AnalyticsDashboardBuilder?: {
    label: string;
    link: string;
  };
};

const REFRESH_TOKEN_KEY = 'biotUser-token';

export const PlatformsLinksMenu: React.FC<PlatformsLinksMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();

  const analyticsDashboardUrl = isValidUrl(AppConfig.ANALYTICS_DASHBOARD_BUILDER_LINK);
  // Remove analyticsDbDeployed when the cloudwatch datasource is deployed in grafana (Tzvika needs to open a task)
  const analyticsDbDeployed = useSelector(analyticsDBSelector.getIsAnalyticsDBDeployed);
  const refreshToken = UmsLogic.getRefreshToken();

  // WithFlag: ANALYTICS_DASHBOARD_BUILDER_LINK
  const { isFeatureActive: isAnalyticsDashboardBuilderFeatureActive } = useFeature({
    flag: 'ANALYTICS_DASHBOARD_BUILDER_LINK',
  });
  // FlagOff: ANALYTICS_DASHBOARD_BUILDER_LINK

  const getAnalyticsLink = () => {
    if (!analyticsDashboardUrl) {
      return '';
    }

    return analyticsDashboardUrl.toString();
  };

  const analyticsDashboardBuilderObject = isAnalyticsDashboardBuilderFeatureActive
    ? {
        AnalyticsDashboardBuilder: {
          label: intl.formatMessage({
            id: 'app-layout.top-menu.platforms-menu.analytics-dashboard-builder',
            defaultMessage: 'Dashboard Builder',
          }),
          link: getAnalyticsLink(),
          disabled: !analyticsDashboardUrl || !analyticsDbDeployed,
          onLinkClicked: () => {
            if (refreshToken) {
              const cookieExpirationDate = new Date(Date.now() + AppConfig.COOKIES_EXPIRATION_IN_MINUTES * 60 * 1000);
              cookiesManager.set(REFRESH_TOKEN_KEY, refreshToken, undefined, cookieExpirationDate);
            }
          },
        },
      }
    : {};

  const platformsData: PlatformsType = {
    manufacturer: {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.platforms-menu.manufacturer',
        defaultMessage: 'Manufacturer Portal',
      }),
      link: addLocaleToSearchParam(AppConfig.PLATFORMS_MANUFACTURER_URL),
    },
    organization: {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.platforms-menu.organization',
        defaultMessage: 'Organization Portal',
      }),
      link: addLocaleToSearchParam(AppConfig.PLATFORMS_ORGANIZATION_URL),
    },
    ...analyticsDashboardBuilderObject,
  };

  return <PlatformsPopper platforms={platformsData} color={theme.palette.grayScale.darker3} />;
};

export default PlatformsLinksMenu;
