import { PopperWithAnchor, SubmitButtonStatus } from '@biotmed/base-components';
import { getEntityTypeIntlDisplayName } from '@biotmed/data-components';
import { GetTemplateResponse } from '@biotmed/sdk-api-provider/lib/types/settings';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { selectors, actions } from 'src/redux/data/template';
import { CustomAttribute, TemplateOverviewResponse } from '@biotmed/settings-sdk';
import { EditModal } from 'src/components/Modals';
import { useFeature } from '@biotmed/react-flags-lib';
import {
  childrenTemplatesFieldName,
  entityTemplateFieldName,
  EntityTemplateFormInitialValues,
} from '../modules/constant';
import { stepComponentsMap } from '../modules/factory';
import { EntityTemplateForm } from './Template';
import { Title } from './Templates.styled';
import { isTemplateInUse } from '../modules/utils';
import ForceConfirmationPopup, {
  useForceConfirmationProps,
  getEditForceConfirmationText,
  EditTemplateForceErrorCodesType,
} from '../../../components/forceConfirmation';
import { useChildrenTemplates } from '../hooks/useChildrenTemplate';
// TODO: CHANGE TO ONE INTERFACE WITH ADD TEMPLATE INTEFACE
export interface EditTemplateProps {
  handleClose: () => void;
  entity?: Entity;
  template?: GetTemplateResponse;
  submitButtonStatus: SubmitButtonStatus;
  submitForm: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
  containerId: string;
  currentTemplateOverview?: TemplateOverviewResponse;
  isOpen: boolean;
  modalWidthDiff?: string;
}

const EditTemplate: React.FC<EditTemplateProps> = props => {
  const {
    handleClose,
    entity,
    template,
    submitButtonStatus,
    submitForm,
    containerId,
    currentTemplateOverview,
    isOpen,
    modalWidthDiff,
  } = props;
  const intl = useIntl();
  const forceErrorCode = useSelector(selectors.getForceAddEditErrorCode);
  const errorDetails = useSelector(selectors.getErrorDetails);
  const dispatch = useDispatch();
  const { isFeatureActive: isRevertFeatureActive } = useFeature({ flag: 'FORCE_POPUP_REVERT_BUTTON' });

  const handleSubmitForm = (values: EntityTemplateForm, forceUpdate: boolean = false) => {
    // TODO: REMOVE ?? '' FROM ENTITY TYPE AND TEMPLATE ID
    submitForm(template?.entityTypeName ?? '', template?.id ?? '', values, childrenTemplates, forceUpdate);
  };

  const steps = useMemo(() => (entity ? stepComponentsMap(entity) : []), [entity]);

  const { childrenTemplates } = useChildrenTemplates(template?.id, isOpen);

  const initialValues: EntityTemplateForm = useMemo(() => {
    if (template && isOpen) {
      return {
        [entityTemplateFieldName]: {
          ...template,
          builtInAttributes: template.builtInAttributes
            ?.slice()
            .sort((a, b) => a.displayName.localeCompare(b.displayName)),
          customAttributes: template.customAttributes
            ?.slice()
            .sort((a, b) => a.id?.localeCompare(b.id) && a.displayName.localeCompare(b.displayName)),
        },
        [childrenTemplatesFieldName]: childrenTemplates ?? [],
      };
    }

    return EntityTemplateFormInitialValues;
  }, [childrenTemplates, template, isOpen]);

  const getAttributesMapper = (formValues: EntityTemplateForm): { [key: string]: CustomAttribute } => {
    const { builtInAttributes, customAttributes } = formValues.entityTemplate;
    const attributes = [...(builtInAttributes ?? []), ...(customAttributes ?? [])];

    return [...attributes].reduce(
      (mapper, newAttr) => ({
        ...mapper,
        [newAttr.id ?? newAttr.name]: newAttr,
      }),
      {},
    );
  };

  const { title, submitLabel, information } = useForceConfirmationProps({
    attributesMapper: getAttributesMapper(initialValues),
    textMapper: getEditForceConfirmationText,
    forceErrorCode: forceErrorCode as EditTemplateForceErrorCodesType,
  });

  const isNewTemplateStatus = {
    templateIsNew: false,
  };
  const isTemplateInUseStatus = {
    templateInUse: isTemplateInUse(currentTemplateOverview?.statistics?.entitiesCount),
  };

  const entityName = entity && intl.formatMessage(getEntityTypeIntlDisplayName(entity.name));

  return (
    <EditModal
      isDisabled={!!forceErrorCode}
      modalWidthDiff={modalWidthDiff}
      isOpen={isOpen}
      steps={steps}
      submitButtonStatus={submitButtonStatus}
      renderTitle={() => (
        <Title>
          {intl.formatMessage(
            {
              id: 'template-edit.modal.header-title',
              defaultMessage: 'Edit {entityName} Template',
            },
            {
              entityName,
            },
          )}
        </Title>
      )}
      initialValues={initialValues}
      handleSubmit={values => handleSubmitForm(values)}
      handleClose={handleClose}
      containerId={containerId}
      additionalValidationData={{ ...isNewTemplateStatus, ...isTemplateInUseStatus, initialValues }}
      additionalFormProps={{
        initialStatus: { ...isNewTemplateStatus, ...isTemplateInUseStatus },
        enableReinitialize: true,
      }}
      renderPageSubmitButton={({ buttonElement, values, revertFormValues = () => {} }) => (
        <PopperWithAnchor
          withArrow
          open={!!forceErrorCode}
          PopperComponent={
            <ForceConfirmationPopup
              onSubmit={() => handleSubmitForm(values, true)}
              onCancel={() => dispatch(actions.onForceAddEditCancel())}
              isButtonsDisabled={submitButtonStatus === SubmitButtonStatus.LOADING}
              title={title}
              submitLabel={submitLabel}
              information={information()}
              onRevert={() => {
                revertFormValues(errorDetails.attributes);
                dispatch(actions.onForceRevert());
              }}
              withRevert={!!errorDetails?.attributes && isRevertFeatureActive}
            />
          }
          handleClickAway={() => {}} // Can possibly be used to highlight the message to notify the user that he has to choose
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [-150, 20],
              },
            },
          ]}
        >
          {buttonElement}
        </PopperWithAnchor>
      )}
    />
  );
};
export default EditTemplate;
