import React from 'react';
import intl from '@biotmed/i18n';
import { Information } from 'src/components/forceConfirmation';
import { ADBLearnMoreLink } from 'src/utils/analyticsDBUtils';
import messages from '../dictionary';

const deleteForceConfirmationProps = () => {
  return {
    submitLabel: intl.current.formatMessage(messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_SUBMIT),
    title: intl.current.formatMessage(messages.DELETE_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE),
    information: () => (
      <Information
        applyConsequencesMessage={intl.current.formatMessage(
          messages.TEMPLATE_ANALYTICS_DB_SYNC_ON_CONSEQUENCES_ACTION,
          {
            link: ADBLearnMoreLink,
            br: <br />,
          },
        )}
      />
    ),
  };
};

export default deleteForceConfirmationProps;
