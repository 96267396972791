import { Column, Table, TableProps } from '@biotmed/base-components';
import { AttributeTypeEnum, createCustomFilter, MoreActionsButton } from '@biotmed/data-components';
import { PluginResponse, PluginResponseDeploymentPackageTypeEnum } from '@biotmed/settings-sdk';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from 'src/redux/data/plugin';
import { deploymentPackageTypeToIntlMessage } from 'src/utils/deploymentPackageTypeToIntlMessage';
import { mapStateToOppositeState } from '../../../redux/data/plugin/modules/mappers';

const PluginTable: React.FC<TableProps<PluginResponse>> = props => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const deploymentPackageTypeColumnSelectableValues =
    Object.values(PluginResponseDeploymentPackageTypeEnum)?.map(value => {
      return {
        name: value,
        displayName: intl.formatMessage(deploymentPackageTypeToIntlMessage[value]),
      };
    }) || [];

  return (
    <Table
      columns={[
        {
          title: intl.formatMessage({ id: 'plugin-configuration.column-name', defaultMessage: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Label, {}, ''),
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-display-name',
            defaultMessage: 'Display Name',
          }),
          dataIndex: 'displayName',
          key: 'displayName',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Label, {}, ''),
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-version',
            defaultMessage: 'Version',
          }),
          dataIndex: 'version',
          key: 'version',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Integer, {}, ''),
          render: (value, record, index) => {
            return (
              <Column width="100px" key={`version-${index}`}>
                {value}
              </Column>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-endpoint-url',
            defaultMessage: 'Endpoint Url',
          }),
          dataIndex: 'endpointUrl',
          key: 'endpointUrl',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Label, {}, ''),
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-runtime',
            defaultMessage: 'Runtime',
          }),
          dataIndex: 'runtime',
          key: 'runtime',
          render: (value, record, index) => {
            return (
              <Column key={`runtime-${index}`} width="90px">
                {value}
              </Column>
            );
          },
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Label, {}, ''),
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-timeout',
            defaultMessage: 'Timeout',
          }),
          dataIndex: 'timeout',
          key: 'timeout',
          render: (value, record, index) => {
            return (
              <Column key={`timeout-${index}`} width="90px">
                {value}
              </Column>
            );
          },
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.Integer, {}, ''),
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-lastModifiedTime',
            defaultMessage: 'Last Modified Time',
          }),
          dataIndex: 'lastModifiedTime',
          key: 'lastModifiedTime',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.DateTime, {}, ''),
          render: (value, record, index) => {
            return <span key={`lastModifiedDate-${index}`}>{value}</span>;
          },
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-creationTime',
            defaultMessage: 'Creation Time',
          }),
          dataIndex: 'creationTime',
          key: 'creationTime',
          sorter: true,
          filterDropdown: createCustomFilter(AttributeTypeEnum.DateTime, {}, ''),
          render: (value, record, index) => {
            return <span key={`creationTime-${index}`}>{value}</span>;
          },
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-deploymentPackageType',
            defaultMessage: 'Deployment Package Type',
          }),
          dataIndex: 'deploymentPackageType',
          key: 'deploymentPackageType',
          sorter: true,
          filterDropdown: createCustomFilter(
            AttributeTypeEnum.MultiSelect,
            { selectableValues: deploymentPackageTypeColumnSelectableValues },
            '',
          ),
          render: (value: string, record: PluginResponse, index: number) => {
            return (
              <Column width="150px" key={`deploymentPackageType-${index}`}>
                {intl.formatMessage(
                  deploymentPackageTypeToIntlMessage[value as PluginResponseDeploymentPackageTypeEnum],
                ) ?? value}
              </Column>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'plugin-configuration.column-cronSchedule',
            defaultMessage: 'Cron Schedule',
          }),
          dataIndex: 'cronSchedule',
          key: 'cronSchedule',
          sorter: false,
          render: (value: string, record: PluginResponse, index: number) => {
            return (
              <Column width="150px" key={`cronSchedule-${index}`}>
                {value}
              </Column>
            );
          },
        },
        {
          title: '',
          key: 'enabledStateActionColumn',
          dataIndex: 'enabledState',
          align: 'right' as any,
          render: (value, record, index) => {
            return (
              <MoreActionsButton
                renderActions={(TriggerComponent, onClose) => () => {
                  const oppositeStateDetails = mapStateToOppositeState(value);
                  return [
                    <TriggerComponent
                      text={oppositeStateDetails.text}
                      Logo={oppositeStateDetails.logo}
                      onClick={(event: any) => {
                        dispatch(
                          actions.onEnableStateUpdate({
                            name: record.name,
                            enabledState: oppositeStateDetails.state,
                          }),
                        );
                        onClose?.();
                      }}
                    />,
                  ];
                }}
                variant="horizontal"
              />
            );
          },
          fixed: 'right' as any,
        },
      ]}
      {...props}
    />
  );
};

export default PluginTable;
