import { getSdkApi } from '@biotmed/sdk-api-provider';
import { useQuery } from '@tanstack/react-query';

export const useChildrenTemplates = (templateId: string | undefined, toRun: boolean = true) => {
  const fetchChildrenTemplates = async (templateId: string = '') => {
    try {
      // if there are more than 100 results, we need to add an iterator that receives a request
      // and returns all of the results from the BE
      const childrenTemplatesResponse = await getSdkApi().settings.templatesApi.search1({
        filter: {
          parentTemplateId: {
            in: [templateId],
          },
        },
      });

      const childrenTemplates = childrenTemplatesResponse?.data?.data.sort((a: any, b: any) =>
        a?.displayName?.localeCompare(b?.displayName),
      );

      return childrenTemplates;
    } catch (e) {
      console.error('Failed to get children templates e', e);
      return [];
    }
  };

  const { data: childrenTemplates, isLoading } = useQuery({
    queryKey: ['templates', 'children', templateId, toRun],
    queryFn: () => {
      return fetchChildrenTemplates(templateId);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return { childrenTemplates, isLoading };
};
