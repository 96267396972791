import { Explained, Link, PopperWithAnchor, SubmitButtonStatus } from '@biotmed/base-components';
import { getEntityTypeIntlDisplayName, EntityType, EntityTypeEnum } from '@biotmed/data-components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AddModal } from 'src/components/Modals';
import { Entity } from 'src/redux/data/entity/modules/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from 'src/redux/data/template';
import AppConfig from 'src/config/AppConfig';
import { stepComponentsMap } from '../modules/factory';
import { EntityTemplateForm } from './Template';
import { BestPracticeArticleLink, GenericTemplateNoteWrapper, Title } from './Templates.styled';
import {
  entityTemplateFieldName,
  EntityTemplateFormInitialValues,
  TEMPLATE_TYPE_TO_LEARN_MORE_URL,
} from '../modules/constant';
import ForceConfirmationPopup, {
  useForceConfirmationProps,
  getAddForceConfirmationText,
  AddTemplateForceErrorCodesType,
} from '../../../components/forceConfirmation';

export interface AddTemplateProps {
  entity?: Entity;
  handleClose: () => void;
  submitButtonStatus: SubmitButtonStatus;
  submitForm: (
    entityType: string,
    templateId: string,
    values: EntityTemplateForm,
    originalChildrenTemplates: any,
    forceUpdate: boolean,
  ) => void;
  containerId: string;
  isOpen: boolean;
  modalWidthDiff?: string;
}

const AddTemplate: React.FC<AddTemplateProps> = props => {
  const { handleClose, entity, submitButtonStatus, submitForm, containerId, isOpen, modalWidthDiff } = props;
  const intl = useIntl();
  const forceErrorCode = useSelector(selectors.getForceAddEditErrorCode);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = useMemo(() => (entity ? stepComponentsMap(entity) : []), [entity]);

  const initialValues: EntityTemplateForm = useMemo(() => {
    if (entity) {
      const { builtInAttributes, templateAttributes } = entity;

      return {
        ...EntityTemplateFormInitialValues,
        [entityTemplateFieldName]: {
          ...EntityTemplateFormInitialValues?.[entityTemplateFieldName],
          builtInAttributes: builtInAttributes?.slice().sort((a, b) => a.displayName.localeCompare(b.displayName)),
          templateAttributes,
          entityTypeName: entity.name,
        },
      };
    }
    return { ...EntityTemplateFormInitialValues };
  }, [entity]);

  const onSubmit = (values: EntityTemplateForm, force: boolean) => {
    submitForm(values.entityTemplate.entityTypeName ?? '', '', values, [], force);
  };

  const entityName = entity && intl.formatMessage(getEntityTypeIntlDisplayName(entity.name));

  const onLearnMoreClick = () => {
    window.open(learnMoreUrl);
  };

  const learnMoreUrl = TEMPLATE_TYPE_TO_LEARN_MORE_URL[entity?.name as EntityType];

  const isNewTemplateStatus = { templateIsNew: true };

  const { title, submitLabel, information } = useForceConfirmationProps({
    textMapper: getAddForceConfirmationText,
    forceErrorCode: forceErrorCode as AddTemplateForceErrorCodesType,
  });

  return (
    <AddModal
      isDisabled={!!forceErrorCode}
      modalWidthDiff={modalWidthDiff}
      isOpen={isOpen}
      steps={steps}
      submitButtonStatus={submitButtonStatus}
      initialValues={initialValues}
      handleSubmit={values => onSubmit(values, false)}
      handleClose={handleClose}
      containerId={containerId}
      additionalFormProps={{ initialStatus: isNewTemplateStatus }}
      additionalValidationData={{ ...isNewTemplateStatus, initialValues }}
      renderTitle={() => (
        <Title>
          {intl.formatMessage(
            { id: 'template-add.modal.header-title', defaultMessage: 'New {entityName} Template' },
            {
              entityName,
            },
          )}
        </Title>
      )}
      renderTopContent={() => (
        <>
          <Explained
            text={intl.formatMessage(
              {
                id: 'template-add.modal.explained',
                defaultMessage:
                  'The {entityName} template enables you to build the data model of your specific {entityNameLowercase}',
              },
              { entityName, entityNameLowercase: entityName?.toLocaleLowerCase() },
            )}
            onLearnMore={learnMoreUrl ? onLearnMoreClick : undefined}
          />
          {entity?.name === EntityTypeEnum.GENERIC_ENTITY && (
            <GenericTemplateNoteWrapper>
              {intl.formatMessage(
                {
                  id: 'template-add.modal.generic-entity.warning',
                  defaultMessage:
                    'Note: Generic templates are not covered by the default ABAC rules. Additional rules may be required to ensure compliance with privacy acts. Please refer to {link}',
                },
                {
                  link: (
                    <BestPracticeArticleLink
                      onClick={() => window.open(AppConfig.BEST_PRACTICE_IMPLEMENTING_HIPAA_ARTICLE_URL)}
                    >
                      Best practices for implementing HIPAA compliance using BioT
                    </BestPracticeArticleLink>
                  ),
                },
              )}
            </GenericTemplateNoteWrapper>
          )}
        </>
      )}
      renderPageSubmitButton={({ buttonElement, values }) => (
        <PopperWithAnchor
          withArrow
          open={!!forceErrorCode}
          PopperComponent={
            <ForceConfirmationPopup
              onSubmit={() => onSubmit(values, true)}
              onCancel={() => dispatch(actions.onForceAddEditCancel())}
              title={title}
              submitLabel={submitLabel}
              information={information()}
              isButtonsDisabled={submitButtonStatus === SubmitButtonStatus.LOADING}
            />
          }
          handleClickAway={() => {}} // Can possibly be used to highlight the message to notify the user that he has to choose
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [-150, 20],
              },
            },
          ]}
        >
          {buttonElement}
        </PopperWithAnchor>
      )}
    />
  );
};

export default AddTemplate;
