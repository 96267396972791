import React from 'react';
import { AttributeTypeEnum } from '@biotmed/data-components';
import AttributeLayout, {
  Delete,
  FlexSeparator,
  LinkedAttributeNameSection,
  NameSection,
  PHISelectSection,
  Required,
  RowFlex,
  TypeSection,
  TypeSectionReadOnly,
} from 'src/components/AttributeLayout';
import { AttributeBaseProps } from './Attribute';
import { VerticalSection } from './Attribute.styled';

// TODO: IMPORT SELECT-ITEM TYPE FROM BASE-COMPONENTS
export interface SelectItem {
  value: string;
  title: string;
}

export interface CustomAttributeProps extends AttributeBaseProps {
  variant: 'custom';

  typeOptions: SelectItem[];
  isNew?: boolean;
  setName: (name: string | null) => void;
  setType: (value: string) => void;
  setPHI: (value: boolean) => void;
  setUniquely: (checked: boolean) => void;
  onDelete?: () => void;
  allowedCategoriesToLinkTo: string[] | undefined;
  requiredReadOnly: boolean;
  basePath?: string;
}

export const CustomAttribute: React.FC<React.PropsWithChildren<CustomAttributeProps>> = props => {
  const {
    attributeValues,
    typeOptions,
    required = false,
    setRequired,
    setDisplayName,
    setName,
    setType,
    setPHI,
    setUniquely,
    children,
    onDelete,
    type = '',
    attrFieldName,
    attributeErrors,
    attributeTouched,
    onSelectedTargetAttributeChange,
    selectedTargetAttribute,
    isNew = false,
    allowedCategoriesToLinkTo,
    requiredReadOnly,
    basePath,
  } = props;

  const headerComponent = (
    <VerticalSection>
      {type === AttributeTypeEnum.Link ? (
        <LinkedAttributeNameSection
          onSelectedTargetAttributeChange={onSelectedTargetAttributeChange}
          setDisplayName={setDisplayName}
          attributeValues={attributeValues}
          attributeErrors={attributeErrors}
          attributeTouched={attributeTouched}
          attrFieldName={attrFieldName}
          allowedCategoriesToLinkTo={allowedCategoriesToLinkTo}
          selectedTargetAttribute={selectedTargetAttribute}
          setName={setName}
          isNew={isNew}
        />
      ) : (
        <NameSection
          value={attributeValues.displayName}
          canChangeTooltipValue
          tooltipValue={attributeValues.name}
          tooltipInputCanChangeProps={{
            onChange: (value, reason) => setName(value),
            onSave: setName,
            helperText: attributeTouched ? attributeErrors?.name : '',
            error: attributeTouched?.name && Boolean(attributeErrors?.name),
            updateTooltipValueByValue: isNew && !attributeTouched?.name,
          }}
          onChange={setDisplayName}
          autoFocus={false}
          fieldName={`${attrFieldName}.displayName`}
          tooltipFieldName={`${attrFieldName}.name`}
          helperText={attributeTouched?.displayName ? attributeErrors?.displayName : ''}
          error={attributeTouched?.displayName && Boolean(attributeErrors?.displayName)}
          attributeId={attributeValues.id || attributeValues.arrayId}
          basePath={attributeValues?.basePath ?? basePath}
        />
      )}
      {isNew ? (
        <TypeSection
          helperText={attributeTouched?.type ? attributeErrors?.type : ''}
          isError={attributeTouched?.type && Boolean(attributeErrors?.type)}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          fieldName={`${attrFieldName}.type`}
        />
      ) : (
        <TypeSectionReadOnly type={attributeValues.type} />
      )}
    </VerticalSection>
  );
  const footerComponent = (
    <RowFlex>
      <RowFlex>
        <PHISelectSection
          phi={attributeValues.phi}
          setPHI={setPHI}
          withUniquely={attributeValues.type === AttributeTypeEnum.Reference}
          uniquely={attributeValues?.referenceConfiguration?.uniquely}
          setUniquely={setUniquely}
        />
      </RowFlex>
      <FlexSeparator />
      <RowFlex>
        <Delete onDelete={onDelete} />
        <Required required={required} setRequired={setRequired} readOnly={requiredReadOnly} />
      </RowFlex>
    </RowFlex>
  );
  return <AttributeLayout header={headerComponent} content={children} footer={footerComponent} />;
};

export default CustomAttribute;
