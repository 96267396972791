import React, { ReactNode } from 'react';
import { Button, SubmitButtonProps } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { ButtonsSection, Content, Wrapper, ForceDeleteButton, MessageHeader } from './ForceConfirmationPopup.styled';

export interface ForceConfirmationPopupProps {
  onSubmit: () => void;
  submitLabel: string;
  onCancel: () => void;
  title: string;
  information: ReactNode;
  isButtonsDisabled: boolean;
  submit?: boolean;
  submitButtonProps?: SubmitButtonProps;
  withRevert?: boolean;
  onRevert?: (attributes: any) => void | null;
}

export const ForceConfirmationPopup = (props: ForceConfirmationPopupProps) => {
  const {
    onSubmit,
    submitLabel,
    onCancel,
    title,
    information,
    isButtonsDisabled,
    submit = false,
    submitButtonProps,
    onRevert,
    withRevert = false,
  } = props;

  const intl = useIntl();

  const buttonProps = submit && submitButtonProps ? { submit, submitButtonProps } : {};

  return (
    <Wrapper $variant={withRevert ? 'with-revert' : 'regular'}>
      <MessageHeader>{title}</MessageHeader>
      <Content>{information}</Content>
      <ButtonsSection>
        <Button size="large" variant="outlined" onClick={onCancel} disabled={isButtonsDisabled}>
          {intl.formatMessage({
            id: 'templates.force-confirmation-popup.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        {withRevert && (
          <Button size="large" variant="outlined" onClick={onRevert} disabled={isButtonsDisabled}>
            {intl.formatMessage({
              id: 'templates.force-confirmation-popup.revert',
              defaultMessage: 'Undo',
            })}
          </Button>
        )}
        <ForceDeleteButton
          {...buttonProps}
          size="large"
          variant="contained"
          onClick={onSubmit}
          disabled={isButtonsDisabled}
        >
          {submitLabel}
        </ForceDeleteButton>
      </ButtonsSection>
    </Wrapper>
  );
};
