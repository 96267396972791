export const isValidUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj;
  } catch {
    return false;
  }
};

export const addQueryParam = (url: URL, key: string, value: string) => {
  url.searchParams.append(key, value);
  return url;
};
