import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ADD_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE: {
    id: 'templates.add.analytics-db-sync-on.title',
    defaultMessage: 'Add Template?',
  },
  EDIT_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE: {
    id: 'templates.edit.analytics-db-sync-on.title',
    defaultMessage: 'Change Attribute?',
  },
  DELETE_TEMPLATE_ANALYTICS_DB_SYNC_ON_TITLE: {
    id: 'templates.delete.analytics-db-sync-on.title',
    defaultMessage: 'Remove Template?',
  },
  TEMPLATE_ANALYTICS_DB_SYNC_ON_SUBMIT: {
    id: 'templates.add-edit.analytics-db-sync-on.submit',
    defaultMessage: 'Stop Analytic DB synchronizing',
  },
  TEMPLATE_ANALYTICS_DB_SYNC_ON_CONSEQUENCES_ACTION: {
    id: 'templates.add-edit.analytics-db-sync-on.consequences-action',
    defaultMessage:
      'This operation will stop the sync with the analytics DB. In order to continue working with the Analytics DB a resync will be required.{br}Please refer to the {link} for further information.',
  },
});

export default messages;
