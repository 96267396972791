import { defineMessages } from 'react-intl';

const messages = defineMessages({
  CHANGED_PROBLEMATIC_ATTRIBUTES: {
    id: 'force.popup.information.changed-problematic-attributes',
    defaultMessage: 'You have made changes to the following observation attributes:',
  },
  UNDO_CONSEQUENCES: {
    id: 'force.popup.information.undo-consequences-action',
    defaultMessage:
      "In order to revert to previous definitions press 'Undo'. In order to continue editing press 'Cancel'",
  },
  ADB_ERROR_NOTE: {
    id: 'force.popup.information.adb.error-notice',
    defaultMessage:
      'Note: In case you are using the BioT Analytics DB a full resync will be executed. For resync implications please refer to the following article {link}',
  },
  FORCE_ERROR_DEFAULT_TITLE: {
    id: 'templates.force-error.default.title',
    defaultMessage: 'Your changes may cause data interruption',
  },
  FORCE_ERROR_DEFAULT_SUBMIT: {
    id: 'templates.force-error.default.submit',
    defaultMessage: 'Continue with this action',
  },
  FORCE_ERROR_DEFAULT_CONSEQUENCES_ACTION: {
    id: 'templates.force-error.default.consequences-action',
    defaultMessage: 'Applying this change could make unknown data interruption',
  },
});

export default messages;
