import { FieldArray, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { Category, Template } from 'src/redux/data/entity/modules/interfaces';
import { Category as CategoryType, categoryToIntlDisplayNameMap } from '@biotmed/data-components';
import { mapAttributesTypeToLabel } from 'src/components/AttributeLayout';
import { customAttributesFieldName } from 'src/routes/Templates/modules/constant';
import AddFieldButton from 'src/components/AddFieldButton';
import { Attribute } from '../../Attribute';
import { EntityTemplateForm } from '../../Template';

type FormikTouchedCurrent = FormikTouched<Template['customAttributes']>;
type FormikErrorsCurrent = FormikErrors<Template['customAttributes']>;
interface CustomFieldArray {
  category: Category;
}

export const CustomAttributesArrayField: React.FC<CustomFieldArray> = props => {
  const { category } = props;

  const intl = useIntl();

  const formik = useFormikContext<EntityTemplateForm>();
  const { errors, values, touched, initialValues } = formik;
  const filteredCustomAttributes = useMemo(() => {
    const { customAttributes } = formik.values.entityTemplate;
    return customAttributes
      ?.map((customAttribute: any, index: number) => ({ attrObject: customAttribute, index }))
      .filter((customAttribute: { attrObject: any; index: number }) => {
        return customAttribute.attrObject?.category.name === category.name;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.entityTemplate.customAttributes]);

  const initialMap: any = useMemo(() => {
    return initialValues?.entityTemplate.customAttributes?.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr };
    }, {});
  }, [initialValues]);

  // TODO CHANGE TO IAttribute
  const newCustomField: any = {
    displayName: '',
    name: '',
    type: '',
    phi: false,
    validation: { mandatory: false },
    category: category as any,
  };

  return (
    <FieldArray name={customAttributesFieldName}>
      {arrayHelpers => {
        const { push, remove } = arrayHelpers;
        return (
          <>
            {filteredCustomAttributes &&
              filteredCustomAttributes.length > 0 &&
              filteredCustomAttributes?.map((customAttribute: any) => {
                const attributeTouched = (touched?.entityTemplate?.customAttributes as FormikTouchedCurrent)?.[
                  customAttribute.index
                ];
                const attributeErrors = (errors?.entityTemplate?.customAttributes as FormikErrorsCurrent)?.[
                  customAttribute.index
                ];

                const attributeValues = values?.entityTemplate?.customAttributes?.[customAttribute.index];

                const attributeInitialValues = attributeValues?.id ? initialMap?.[attributeValues.id] : undefined;
                return (
                  <Attribute
                    key={customAttribute.attrObject.arrayId ?? customAttribute.attrObject.id}
                    variant="custom"
                    attrFieldName={`${customAttributesFieldName}.${customAttribute.index}`}
                    attributeTouched={attributeTouched}
                    attributeValues={attributeValues}
                    attributeErrors={attributeErrors}
                    type={customAttribute.attrObject.type}
                    onDelete={() => remove(customAttribute.index)}
                    typeOptions={category.customSupportedTypes.map(type => ({
                      value: type,
                      // TODO: merge this and the one in builtInAttribute
                      title: mapAttributesTypeToLabel.get(type)
                        ? intl.formatMessage(mapAttributesTypeToLabel.get(type))
                        : type,
                    }))}
                    basePath={category.customBasePath}
                    isNewCustom={!customAttribute.attrObject.id && !customAttribute.attrObject.isSaved}
                    attributeInitialValues={attributeInitialValues}
                    allowedCategoriesToLinkTo={category.allowedCategoriesToLinkTo}
                    supportMandatoryValidation={category.supportMandatoryValidation}
                  />
                );
              })}
            {category.customSupportedTypes.length > 0 && (
              <AddFieldButton shadow onClick={() => push({ ...newCustomField, arrayId: v4() })}>
                {intl.formatMessage(
                  { id: 'template.modal.add-custom-field-button', defaultMessage: '+ Add {categoryName} Field' },
                  { categoryName: intl.formatMessage(categoryToIntlDisplayNameMap[category.name as CategoryType]) },
                )}
              </AddFieldButton>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
export default CustomAttributesArrayField;
