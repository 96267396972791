import { useFeature } from '@biotmed/react-flags-lib';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';
import EnvironmentConfiguration from 'src/routes/EnvironmentConfiguration';
// WithFlag: PLUGIN_CONFIGURATION_PAGE-V2
import PluginConfigurationV2 from 'src/routes/PluginConfigurationV2';
// FlagOff: PLUGIN_CONFIGURATION_PAGE-V2
import PluginConfiguration from 'src/routes/PluginConfiguration';

import TechnicalInformation from 'src/routes/TechnicalInformation';
import Languages from 'src/routes/Languages/components/Languages';
import AnalyticsDB from 'src/routes/ADB';
import { useSelector } from 'react-redux';
import { selectors as analyticsDBSelector } from '../../ADB';
import AuthRoutes from '../../Auth';
import ChangePasswordPage from '../../ChangePasswordPage/ChangePasswordPage';
import PortalBuilder from '../../PortalBuilder';
import Templates from '../../Templates';

import RoutesEnum from '../modules/Routes';

interface RoutePagesProps {
  isLoggedIn: boolean;
}

export const PublicPage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  return !isLoggedIn ? <AuthRoutes /> : <Navigate to="/" replace />;
};

export const PrivatePage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;
  const { isFeatureActive: isImportExportPageFeatureActive } = useFeature({ flag: 'IMPORT_EXPORT_PAGE' });
  const { isFeatureActive: isLanguagesPageFeatureActive } = useFeature({ flag: 'LANGUAGES_PAGE' });
  const { isFeatureActive: isAnalyticsDBFeatureActive } = useFeature({ flag: 'ANALYTICS_DB_PAGE' });
  const { isFeatureActive: isPluginConfigurationPageFeatureActive } = useFeature({
    flag: 'PLUGIN_CONFIGURATION_PAGE-V2',
  });
  const isAnalyticsDBDeployed = useSelector(analyticsDBSelector.getIsAnalyticsDBDeployed);

  return isLoggedIn ? (
    <AppLayout>
      <Routes>
        <Route caseSensitive path={`/${RoutesEnum.TEMPLATES}`} element={<Templates />} />

        <Route caseSensitive path={`/${RoutesEnum.PORTAL_BUILDER}`} element={<PortalBuilder />} />

        <Route caseSensitive path={`/${RoutesEnum.CHANGE_PASSWORD}`} element={<ChangePasswordPage />} />

        <Route caseSensitive path={`/${RoutesEnum.TECHNICAL_INFORMATION}`} element={<TechnicalInformation />} />

        {isAnalyticsDBFeatureActive && isAnalyticsDBDeployed && (
          <Route caseSensitive path={`/${RoutesEnum.ANALYTICS_DB}`} element={<AnalyticsDB />} />
        )}

        {/* WithFlag: PLUGIN_CONFIGURATION_PAGE-V2 */}
        <Route
          caseSensitive
          path={`/${RoutesEnum.PLUGIN_CONFIGURATION}`}
          element={isPluginConfigurationPageFeatureActive ? <PluginConfigurationV2 /> : <PluginConfiguration />}
        />

        {isImportExportPageFeatureActive && (
          <Route
            caseSensitive
            path={`/${RoutesEnum.ENVIRONMENT_CONFIGURATION}`}
            element={<EnvironmentConfiguration />}
          />
        )}
        {isLanguagesPageFeatureActive && (
          <Route caseSensitive path={`/${RoutesEnum.LANGUAGES}`} element={<Languages />} />
        )}
        <Route path="*" element={<Navigate to={`./${RoutesEnum.TEMPLATES}`} replace />} />
      </Routes>
    </AppLayout>
  ) : (
    <Navigate to={{ pathname: `/${RoutesEnum.AUTH}` }} replace />
  );
};
