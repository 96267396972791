import React from 'react';
import { useIntl } from 'react-intl';
import * as dictionary from '../../modules/dictionaries';

interface TooltipContentProps {}

const TooltipContent: React.FC<TooltipContentProps> = props => {
  const intl = useIntl();

  return (
    <>
      <div>
        <b>{intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_ON_FIRST)}</b>
        {' - '}
        {intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_ON_SECOND)}
      </div>
      <div>
        <b>{intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_OFF_FIRST)}</b>
        {' - '}
        {intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_ACTIVE_AND_SYNC_OFF_SECOND)}
      </div>
      <div>
        <b>{intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_INITIALIZING_FIRST)}</b>
        {' - '}
        {intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_INITIALIZING_SECOND)}
      </div>
      <div>
        <b>{intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_STOPPING_INITIALIZATION_FIRST)}</b>
        {' - '}
        {intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_STOPPING_INITIALIZATION_SECOND)}
      </div>
      <div>
        <b>{intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_INACTIVE_FIRST)}</b>
        {' - '}
        {intl.formatMessage(dictionary.TOOLTIP_STATUS_TEXT_INACTIVE_SECOND)}
      </div>{' '}
    </>
  );
};

export default TooltipContent;
