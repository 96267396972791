import React, { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Explained, Separator, Spinner, ValidationModal } from '@biotmed/base-components';
import AppConfig from 'src/config/AppConfig';
import { useSelector, useDispatch } from 'react-redux';
import { AnalyticsDBInformationResponseActivationStateEnum } from '@biotmed/dms-sdk';
import { PageMainTitle } from 'src/components/CommonStyledComponents';
import { selectors, actions } from '../../modules/slice';
import {
  Layout,
  ContactUsContainer,
  ContactUsLink,
  ModalTextContainer,
  Text,
  BoldText,
  StartSyncButton,
  StopSyncButton,
  ContentContainer,
} from './ADBPage.styled';
import { getSyncControlText, getButtonText, getStatusText } from '../../modules/dictionaries';
import ContentView from './ContentView';
import ADBStatus from './ADBStatus';
import { getIcon } from '../../modules/mappers';
import ConnectionDetails from './ConnectionDetails';

interface AnalyticsDBProps {}

const AnalyticsDB: React.FC<AnalyticsDBProps> = props => {
  const activationState =
    useSelector(selectors.getActivationState) ?? AnalyticsDBInformationResponseActivationStateEnum.Inactive;
  const connectionDetails = useSelector(selectors.getConnectionDetails);
  const { databaseName, host, port } = connectionDetails;
  const [isStopValidationModalOpen, setStopValidationModalOpen] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(actions.onMount());

    return () => {
      dispatch(actions.onUnmount());
    };
  }, []);

  useEffect(() => {
    if (
      isStopValidationModalOpen &&
      activationState !== AnalyticsDBInformationResponseActivationStateEnum.Initializing
    ) {
      closeStopValidationModal();
    }
  }, [activationState]);

  const startInitialization = () => {
    dispatch(actions.onStartInitialization());
  };

  const stopInitialization = () => {
    dispatch(actions.onStopInitialization());
    closeStopValidationModal();
  };

  const stopSynchronization = () => {
    dispatch(actions.onStopSynchronization());
    closeStopValidationModal();
  };

  const openStopValidationModal = () => {
    setStopValidationModalOpen(true);
  };

  const closeStopValidationModal = () => {
    setStopValidationModalOpen(false);
  };

  const getValidationModalComp = (stopAction: () => void, message: string) => {
    return (
      <ValidationModal
        open={isStopValidationModalOpen}
        onAgree={stopAction}
        agreeButtonLabel={intl.formatMessage({
          id: 'analytics-db.validation-modal.agree-button',
          defaultMessage: 'Stop',
        })}
        onDismiss={closeStopValidationModal}
        dismissButtonLabel={intl.formatMessage({
          id: 'analytics-db.validation-modal.dismiss-button',
          defaultMessage: 'Cancel',
        })}
        maxWidth={false}
      >
        <ModalTextContainer>
          <Text>{message}</Text>
          <BoldText>
            {intl.formatMessage({
              id: 'analytics-db.validation-modal.message2',
              defaultMessage: `Are you certain you wish to proceed with stopping?`,
            })}
          </BoldText>
        </ModalTextContainer>
      </ValidationModal>
    );
  };

  const SpinningIcon = (Icon: ReactNode) => <Spinner variant="regular-spin">{Icon}</Spinner>;

  const getSyncButton =
    (SyncButton: typeof StopSyncButton | typeof StartSyncButton, onClick: () => void, disabled = false) =>
    (children: ReactNode) =>
      (
        <SyncButton size="medium" variant="contained" onClick={onClick} disabled={disabled}>
          {children}
        </SyncButton>
      );

  const getContentViewProps = (
    activationState: AnalyticsDBInformationResponseActivationStateEnum,
    Icon: ReactNode,
    SyncButton: (children: ReactNode) => ReactNode,
  ) => ({
    syncControlText: intl.formatMessage(getSyncControlText(activationState)),
    SyncButton: SyncButton(intl.formatMessage(getButtonText(activationState))),
    AdbStatus: <ADBStatus statusText={intl.formatMessage(getStatusText(activationState))} Icon={Icon} />,
  });

  const contentViewMapper: { [key: string]: ReactNode } = {
    [AnalyticsDBInformationResponseActivationStateEnum.Inactive]: (
      <ContentView
        {...getContentViewProps(
          AnalyticsDBInformationResponseActivationStateEnum.Inactive,
          getIcon(AnalyticsDBInformationResponseActivationStateEnum.Inactive),
          getSyncButton(StartSyncButton, startInitialization),
        )}
      />
    ),
    [AnalyticsDBInformationResponseActivationStateEnum.Initializing]: (
      <ContentView
        {...getContentViewProps(
          AnalyticsDBInformationResponseActivationStateEnum.Initializing,
          SpinningIcon(getIcon(AnalyticsDBInformationResponseActivationStateEnum.Initializing)),
          getSyncButton(StopSyncButton, openStopValidationModal),
        )}
        ValidationModal={getValidationModalComp(
          stopInitialization,
          intl.formatMessage({
            id: 'analytics-db.validation-modal.message.initializing',
            defaultMessage:
              'After halting the initialization process, in order to finalize the construction of your analytics DB, it will be necessary to clear all existing data and initiate the process anew.',
          }),
        )}
      />
    ),
    [AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization]: (
      <ContentView
        {...getContentViewProps(
          AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization,
          SpinningIcon(getIcon(AnalyticsDBInformationResponseActivationStateEnum.StoppingInitialization)),
          getSyncButton(StopSyncButton, () => {}, true),
        )}
      />
    ),
    [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn]: (
      <ContentView
        {...getContentViewProps(
          AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn,
          getIcon(AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOn),
          getSyncButton(StopSyncButton, openStopValidationModal),
        )}
        ValidationModal={getValidationModalComp(
          stopSynchronization,
          intl.formatMessage({
            id: 'analytics-db.validation-modal.message.active-sync-on',
            defaultMessage:
              'After halting data synchronization, the sole method to recommence it is by reinitializing the database.',
          }),
        )}
      />
    ),
    [AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff]: (
      <ContentView
        {...getContentViewProps(
          AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff,
          getIcon(AnalyticsDBInformationResponseActivationStateEnum.ActiveAndSyncOff),
          getSyncButton(StartSyncButton, startInitialization),
        )}
      />
    ),
  };

  const getContentView = () => {
    return contentViewMapper[activationState];
  };

  return (
    <Layout>
      <PageMainTitle>
        {intl.formatMessage({ id: 'analytics-db.main-title', defaultMessage: 'Analytics DB' })}
      </PageMainTitle>
      <Explained
        text={intl.formatMessage({
          id: 'analytics-db.explained',
          defaultMessage:
            'The Analytics DB allows you to create complex dashboards and advanced data analysis. This page allows you to control different aspects of the Analytics DB operations.',
        })}
        onLearnMore={() => window.open(AppConfig.ADB_LEARN_MORE_URL)}
      />
      <ContentContainer>
        {getContentView()}
        <Separator height="30px" />
        <ConnectionDetails databaseName={databaseName} port={port} host={host} />
        <Separator height="45px" />
        <ContactUsContainer>
          {intl.formatMessage({ id: 'analytics-db.contact-us', defaultMessage: 'For DB user credentials please' })}
          <ContactUsLink
            to="#"
            onClick={e => {
              e.preventDefault();
              window.open(AppConfig.CONTACT_BIOT_SUPPORT_URL, '_blank');
            }}
          >
            {intl.formatMessage({ id: 'analytics-db.contact-us-link', defaultMessage: 'contact us' })}
          </ContactUsLink>
        </ContactUsContainer>
      </ContentContainer>
    </Layout>
  );
};

export default AnalyticsDB;
